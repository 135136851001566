import * as React from 'react'
import { useContext, useState, useEffect, useRef } from 'react'
import { v4 } from 'uuid'
import { useAccordionGlueResult } from '../hooks/useAccordionGlue'
import { getDimensions } from '../modules/helpers'

// @ts-ignore
const _window: any = (typeof window !== 'undefined' && window) || global

export const AccordionContext = React.createContext<any>(null)

type AccordionPropsType = {
    className?: string
    defaultOpen?: boolean
    isOpen?: boolean
    collapseOnClick?: boolean
    /** glue is an object created with the hook useAccordionGlue() */
    glue?: useAccordionGlueResult
    tag?: 'div' | 'span' | 'p' | 'tr' | 'li'
}

export const AccordionContained: React.FC<AccordionPropsType> = function Accordion(props) {
    const { tag = 'div', collapseOnClick = true, isOpen: controlledIsOpen } = props
    const [_isOpen, setIsOpen] = useState(!!props.defaultOpen || false)
    const isOpen = controlledIsOpen !== void 0 ? controlledIsOpen : _isOpen
    const id = useRef<any>(null)
    !id.current && (id.current = v4())
    useEffect(() => {
        props.glue?.registerGlue(id.current)
        props.defaultOpen && props.glue?.setGlueOpenId(id.current)
    }, [])
    useEffect(() => {
        props.glue && isOpen && props.glue?.glueOpenId !== id.current && props.glue?.setGlueOpenId(id.current)
    }, [isOpen])
    useEffect(() => {
        props.glue?.glueOpenId && props.glue?.glueOpenId !== id.current && setIsOpen(false)
    }, [props.glue?.glueOpenId])
    const toggle = (e: any) => {
        !collapseOnClick && setIsOpen(true)
        collapseOnClick && setIsOpen(!isOpen)
    }
    const className = (props.className ? props.className + ' ' : '') + ((isOpen && 'accordion-expanded') || 'accordion-collapsed')
    let jsx
    switch (tag) {
        case 'span':
            jsx = <span className={className}>{props.children}</span>
            break
        case 'p':
            jsx = <p className={className}>{props.children}</p>
            break
        case 'tr':
            jsx = <tr className={className}>{props.children}</tr>
            break
        case 'li':
            jsx = <li className={className}>{props.children}</li>
            break
        default:
            jsx = <div className={className}>{props.children}</div>
            break
    }
    return (
        <>
            <AccordionContext.Provider value={{ isOpen, toggle, tag }}>{jsx}</AccordionContext.Provider>
        </>
    )
}
const AccordionTrigger = (props: any) => {
    const { toggle, tag: contextTag } = useContext(AccordionContext)
    const { tag = contextTag || 'div' } = props
    switch (tag) {
        case 'span':
            return (
                <span onClick={toggle} className={props.className}>
                    {props.children}
                </span>
            )
        case 'p':
            return (
                <p onClick={toggle} className={props.className}>
                    {props.children}
                </p>
            )
        case 'tr':
            return (
                <tr onClick={toggle} className={props.className}>
                    {props.children}
                </tr>
            )
        case 'li':
            return (
                <li onClick={toggle} className={props.className}>
                    {props.children}
                </li>
            )
        default:
            return (
                <div onClick={toggle} className={props.className}>
                    {props.children}
                </div>
            )
    }
}
const dummyObj = {}
const AccordionContent = (props: any) => {
    const { isOpen, tag: contextTag } = useContext(AccordionContext)
    const { tag = contextTag || 'div' } = props
    const [dimensions, setDimensions] = useState<any>(null)

    const targetRef = useRef<any>(null)
    useEffect(() => {
        if (!dimensions && targetRef && targetRef.current) {
            const measure = () => {
                let temp = targetRef.current.style.height
                targetRef.current.style.height = 'unset'
                setDimensions(getDimensions(targetRef.current))
                targetRef.current.style.height = temp
            }
            measure()
            _window.addEventListener('resize', measure)
            return () => {
                _window.removeEventListener('resize', measure)
            }
        }
        return
    }, [targetRef.current, dimensions?.height])
    const className = 'transition-all transition-ease-in-out duration-300 overflow-hidden ' + (isOpen ? '' : 'h-0')
    const style = isOpen ? { height: dimensions?.height || 'auto' } : dummyObj
    switch (tag) {
        case 'span':
            return (
                <span ref={targetRef} style={style} className={className}>
                    {props.children}
                </span>
            )
        case 'p':
            return (
                <p ref={targetRef} style={style} className={className}>
                    {props.children}
                </p>
            )
        case 'tr':
            return (
                <tr ref={targetRef} style={style} className={className}>
                    {props.children}
                </tr>
            )
        default:
            return (
                <div ref={targetRef} style={style} className={className}>
                    {props.children}
                </div>
            )
    }
}
const AccordionContext_ = (props: any) => {
    const accordionContext = useContext(AccordionContext)
    const { children } = props
    return children(accordionContext)
}
const AccordionIsOpen = (props: any) => {
    return <AccordionContainer.Context>{({ isOpen }: any) => (isOpen && <>{props.children}</>) || null}</AccordionContainer.Context>
}
const AccordionIsClose = (props: any) => {
    return <AccordionContainer.Context>{({ isOpen }: any) => (!isOpen && <>{props.children}</>) || null}</AccordionContainer.Context>
}

const AccordionContainer = (props: any) => <AccordionContained {...props} />

AccordionContainer.Trigger = AccordionTrigger
AccordionContainer.Content = AccordionContent
AccordionContainer.Context = AccordionContext_
AccordionContainer.IsOpen = AccordionIsOpen
AccordionContainer.IsClose = AccordionIsClose

export default AccordionContainer
