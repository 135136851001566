import React, { useState } from 'react'
import { GALLERY_THUMBNAILS_SIZE } from '../constants'
import { TypeImageMenu } from '../types'
import { useConfig } from './hooks'
import Image from './Image'

type TypeGalleryProps = { image_menu_id_array: string[] }
const Gallery = React.memo(function Gallery(props: TypeGalleryProps) {
    const { image_menu_id_array } = props
    const { image_menu_array: image_menu_array_global } = useConfig()
    const image_menu_array = image_menu_id_array?.map((id) => image_menu_array_global.find((im) => im.id === id)!) ?? []
    const [imageMenu, setImageMenu] = useState<TypeImageMenu>(image_menu_array[0])
    return (
        <div className="h-full w-full relative">
            {image_menu_array.map((image_menu) => (
                <Image
                    key={image_menu.id}
                    src={'image-menu/' + image_menu.id + '.' + image_menu.extension + '?' + image_menu.key}
                    alt={image_menu.alt}
                    className={`w-full h-full object-contain ${imageMenu.id !== image_menu.id ? 'hidden' : ''}`}
                />
            ))}
            <div className="absolute w-full bottom-0 flex justify-center">
                {image_menu_array.map((image_menu) => (
                    <Image
                        key={image_menu.id}
                        src={'image-menu/' + image_menu.id + '.' + image_menu.extension + '?' + image_menu.key}
                        alt={image_menu.alt}
                        className="p-4 pb-8 cursor-pointer gallery-thumbnail"
                        resize={{ format: 'cover', width: GALLERY_THUMBNAILS_SIZE, height: GALLERY_THUMBNAILS_SIZE }}
                        onClick={() => setImageMenu(image_menu)}
                    />
                ))}
            </div>
        </div>
    )
})

export default Gallery
