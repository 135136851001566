import { Icon } from '@iconify/react'
import { useMemo } from 'react'
import useAppContext from '../hooks/useAppContext'
import { TypeLocation } from '../types'
import { useConfig } from './hooks'
// import locationIcon from '@iconify/icons-mdi/map-marker'

const ZOOM_MIN_FOR_LABEL = 14.999

const LocationLabel = (props: any) => {
    const { className, color, label } = props
    const { map_type } = useAppContext()
    const inverse = map_type === 'hybrid' || map_type === 'satellite'
    return (
        <div
            className={
                'absolute text-base text-center map-location-label leading-tight mt-1 ' +
                (color || '') +
                (className ? ` ${className}` : '') +
                (inverse ? ' satellite' : '')
            }
        >
            {label}
        </div>
    )
}
export const LocationPin = ({ text }: any) => {
    const { color_array } = useConfig()
    const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    return (
        <div className="pin">
            <Icon icon="mdi:map-marker" className="pin-icon" color={nav_item_color?.color} />
            <p className="pin-text">{text}</p>
        </div>
    )
}

export const getLocationIcon = ({ type, icon }: TypeLocation) => {
    if (icon) return icon
    switch (type) {
        case 'school':
            return 'zondicons:portfolio'
        // return 'ic:baseline-school'
        case 'medical':
            return 'medical-icon:i-hospital'
        case 'culture':
            return 'entypo:open-book'
        case 'nature':
            return 'bxs:leaf'
        case 'sport':
            return 'icon-park-solid:sport'
        case 'commerce':
            return 'fa:shopping-bag'
        case 'main':
            return 'mdi:map-marker'
    }
    return 'ci-location'
}
export const LocationIcon = (props: any) => {
    const { type } = props
    const { color_array } = useConfig()
    const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    let className = '',
        style = {}
    switch (type) {
        case 'school':
            className = 'bg-purple-600 text-white'
            break
        case 'medical':
            className = 'bg-blue-900 text-white'
            break
        case 'culture':
            className = 'bg-pink-600 text-white'
            break
        case 'nature':
            className = 'bg-green-600 text-white'
            break
        case 'sport':
            className = 'bg-green-600 text-white'
            break
        case 'commerce':
            className = 'bg-orange-600 text-white'
            break
        case 'main':
            className = ''
            style = { color: nav_item_color?.color }
            break
    }
    return (
        <div className={'map-icon ' + className}>
            <Icon icon={getLocationIcon(props)} className="pin-icon" style={style} />
        </div>
    )
}

export const LocationSchool = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-purple-600" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationMedical = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-blue-900" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationCulture = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-pink-600" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationNature = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-green-600" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationSport = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-green-600" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationCommerce = (props: any) => {
    const { label, zoom } = props
    return (
        <div className="relative location-marker">
            <LocationIcon {...props} />
            {(!zoom || zoom > ZOOM_MIN_FOR_LABEL) && label && <LocationLabel label={label} color="text-orange-600" className={'zoom-' + zoom} />}
        </div>
    )
}
export const LocationGeneric = (props: any) => {
    return <div className="map-icon bg-blue-500 text-white">{/* <Icon icon={props.icon || 'game-icons:school-bag'} className="pin-icon" /> */}</div>
}

export const Location = (props: TypeLocation) => {
    const { type } = props
    if (type === 'school') return <LocationSchool {...props} />
    if (type === 'medical') return <LocationMedical {...props} />
    if (type === 'culture') return <LocationCulture {...props} />
    if (type === 'nature') return <LocationNature {...props} />
    if (type === 'sport') return <LocationSport {...props} />
    if (type === 'commerce') return <LocationCommerce {...props} />
    if (type === 'main') return <LocationPin {...props} />
    return <LocationGeneric {...props} />
}
