import { constants } from 'buffer'
import React from 'react'
import useConfig from '../hooks/useConfig'
import ImageMenuZone from './ImageMenuZone'
import Image from './Image'

type TypeImageMenuProps = {
    id: string
}

const ImageMenu = (props: TypeImageMenuProps) => {
    const { image_menu_zone_array, image_menu_array, zone_stroke_color, zone_stroke_width, zone_color, zone_color_emphasis, click_zone_array } = useConfig()
    const { id } = props
    const image_menu = image_menu_array.find((im) => im.id === id)

    const image_menu_zone_id_array = image_menu_zone_array.map((i) => i.id)
    const cz_array = click_zone_array.filter((cz) => image_menu_zone_id_array.includes(cz.image_menu_zone_id!))
    const click_zone_style = cz_array.map((cz) => {
        const color = image_menu_zone_array.find((imz) => imz.id === cz.image_menu_zone_id)?.color || zone_color_emphasis
        return `[data-zone-id="${cz.id}"] path:hover {
    ${color ? `fill: ${color} !important; opacity: 0.3` : ''}
}`
    }).join(`
`)

    return (
        <div className="relative">
            {((zone_color || zone_stroke_color || zone_stroke_width) && (
                <>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: click_zone_style,
                        }}
                    />
                </>
            )) ||
                null}
            {image_menu_zone_array.map((imz) => (
                <ImageMenuZone key={imz.id} image_menu_zone={imz} />
            ))}
            {(image_menu && (
                <Image
                    src={'image-menu/' + image_menu.id + '.' + image_menu.extension + '?' + image_menu.key}
                    alt={image_menu.alt}
                    className="max-w-screen max-h-screen"
                />
            )) ||
                null}
        </div>
    )
}

export default ImageMenu
