import { TypeSequence } from '../types'

export interface TypeDimensions {
    width: number
    height: number
    top: number
    left: number
    x: number
    y: number
    right: number
    bottom: number
}
export function getDimensions(node: any): TypeDimensions {
    const rect: any = node.getBoundingClientRect()

    return {
        width: rect.width,
        height: rect.height,
        top: 'y' in rect ? rect.y : rect.top,
        left: 'x' in rect ? rect.x : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    }
}

export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

export const RAF = (predicate: any) => {
    window.requestAnimationFrame(() => window.requestAnimationFrame(predicate))
}
export const updateObjInArray = (arr?: any[]) => (obj: any) => {
    if (!arr) return []
    return arr.find((i) => i.id === obj.id) ? arr.map((i) => (i.id === obj.id ? obj : i)) : [...arr, obj]
}
export const identifySequence = (sequence_array: TypeSequence[]) => (target: string) => sequence_array.find((s) => s.url === target || s.id === target)
