import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const Link = React.memo(function Link(props: any) {
    const { to, children } = props
    return to?.substring(0, 4) === 'http' ? (
        <a href={to}>{children}</a>
    ) : (
        <RouterLink {...props} className={(props.className ?? '') + ' transition duration-500'} />
    )
})

export default Link
