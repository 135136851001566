import React, { useMemo, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DEFAULT_NAV_ITEM_EMPHASIS_COLOR } from '../constants'
import useGetParams from '../hooks/useGetParams'
import { TypeGallery, TypeLocation, TypeNavigationItem } from '../types'
import { useAdmin, useConfig } from './hooks'
import Link from './Link'
import Image from './Image'
// import Icon from './Icon'
import { Icon } from '@iconify/react'
import { LocationIcon } from './LocationMarkers'
import Accordion from './Accordion'
import useAppContext from '../hooks/useAppContext'
import { TypeAppContext } from '../contexts'
import { identifySequence, RAF } from '../modules/helpers'
import useResizeObserver from '@react-hook/resize-observer'

const NavigationContainer = (props: any) => {
    const { selected_navigation_item } = useGetParams()
    // console.log({ selected_navigation_item })
    return <Navigation selected_navigation_item={selected_navigation_item} />
}
const Navigation = React.forwardRef(function Navigation(props: any) {
    const admin = useAdmin()
    const { selected_navigation_item } = props
    const { navigation_array, color_array, sequence_array, gallery_array, display_logo, title, display_navigation_logo, short_description } = useConfig()
    // const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    const nav_item_emphasis_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis'), [])
    // const nav_item_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_text'), [])
    // const nav_item_emphasis_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis_text'), [])

    const nav_item_style = {
        // backgroundColor: nav_item_color?.color ?? DEFAULT_NAV_ITEM_COLOR,
        // color: nav_item_text_color?.color ?? DEFAULT_NAV_ITEM_TEXT_COLOR,
    }
    const nav_item_emphasis_style = {
        color: nav_item_emphasis_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_COLOR,
        // color: nav_item_emphasis_text_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR,
    }

    const shouldBeExpanded = (ni: TypeNavigationItem) => {
        if (ni?.target_model === 'sequence_group' && selected_navigation_item?.target_model === 'sequence_group') {
            return ni.target_id === selected_navigation_item?.target_id
        }
        if (ni?.target_model === 'sequence_group' && selected_navigation_item?.target_model === 'sequence') {
            const sequence_group_id = sequence_array?.find((s) => s.id === selected_navigation_item?.target_id)?.sequence_group_id
            return ni.target_id === sequence_group_id
        }
        if (ni?.target_model === 'gallery' && ni === selected_navigation_item && selected_navigation_item.target_id_array) {
            return true
        }
        return false
    }

    const [main_navigation_collapsed, setMainNavigationCollapsed] = useState(false)

    const ref = useRef<any>()
    const [main_navigation_width, setMainNavigationWidth] = useState<any>()
    useResizeObserver(ref, (e) => {
        const { left, top, width, height } = ref.current.getBoundingClientRect()
        setMainNavigationWidth(width)
    })
    return (
        <div
            id="main-navigation"
            className={
                'relative flex flex-col h-full bg-white transition duration-500' +
                (admin.enabled ? ' pt-12' : '') +
                (main_navigation_collapsed ? ' main-navigation-collapsed' : '')
            }
            style={main_navigation_collapsed ? { marginLeft: -1 * main_navigation_width } : undefined}
            ref={ref}
        >
            <div
                className={'absolute top-8 right-0' + (admin.enabled ? ' mt-12' : '')}
                style={{ marginRight: '-1rem' }}
                onClick={() => setMainNavigationCollapsed(!main_navigation_collapsed)}
            >
                <div
                    className={
                        'bg-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer main-navigation-btn' +
                        (main_navigation_collapsed ? ' collapsed' : '')
                    }
                >
                    {(main_navigation_collapsed && <Icon icon="uil:angle-right-b" />) || <Icon icon="uil:angle-left-b" />}
                </div>
            </div>
            <div className={'bg-gray-50 border-b border-gray-200 text-center flex flex-col justify-center items-center px-20 py-6 text-gray-700'}>
                <div className="text-4xl font-thin flex justify-center items-center">
                    {display_navigation_logo && (
                        <span className={'navigation-item-logo-container inline-block'}>
                            <Image src={'/navigation_logo.png'} className="max-h-6 pr-3" />
                        </span>
                    )}

                    {title}
                </div>
                {(short_description && <div className="tracking-widest text-sm text-gray-500 mt-1">{short_description}</div>) || null}
            </div>
            <ul className="h-full overflow-y-auto text-gray-800 pb-3">
                {navigation_array?.map((navigation_item, index) => (
                    <li key={index} className="pt-5">
                        <NavigationItem
                            navigation_item={navigation_item}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                            isSelected={
                                navigation_item?.target_model === selected_navigation_item?.target_model &&
                                navigation_item?.target_id === selected_navigation_item?.target_id
                            }
                            isExpanded={shouldBeExpanded(navigation_item)}
                            selected_navigation_item={selected_navigation_item}
                        />
                    </li>
                ))}
            </ul>
            {display_logo && (
                <div id="logo" className={'fixed top-0 right-0 p-3 z-20 ' + (admin.enabled ? 'mt-16' : 'mt-12')}>
                    <Link to={'/'}>
                        <Image src={`logo.png`} />
                    </Link>
                </div>
            )}
        </div>
    )
})

type TypeNavigationItemProps = {
    navigation_item: TypeNavigationItem
    nav_item_style?: any
    nav_item_emphasis_style?: any
    isSelected: boolean
    isExpanded: boolean
    selected_navigation_item: TypeNavigationItem
}

const useGetSequenceFullUrl = (sequence_id: string) => {
    const { sequence_array, sequence_group_array } = useConfig()
    const sequence = sequence_array.find((s) => s.id === sequence_id)
    const sequence_group = sequence_group_array.find((sg) => sg.id === sequence?.sequence_group_id)
    return `/${sequence_group?.url ?? sequence_group?.id}/${sequence?.url ?? sequence?.id}`
}
const useGetSequenceGroupFullUrl = (sequence_group_id: string) => {
    const { sequence_array, sequence_group_array } = useConfig()
    const sequence_group = sequence_group_array.find((sg) => sg.id === sequence_group_id)
    const sequence_array_ = sequence_array.filter((s) => s.sequence_group_id === sequence_group_id)
    const sequence = sequence_array_?.find((s) => s.default) || sequence_array_?.[0]
    return `/${sequence_group?.url ?? sequence_group?.id}/${sequence?.url ?? sequence?.id}`
}

const NavigationItem = React.memo(function NavigationItem(props: TypeNavigationItemProps) {
    const { navigation_item, nav_item_style, nav_item_emphasis_style, isSelected, isExpanded, selected_navigation_item } = props
    const [hovered, setHovered] = useState(false)
    const config = useConfig()
    const sequence_url = useGetSequenceFullUrl(navigation_item?.target_id || '')
    const sequence_group_url = useGetSequenceGroupFullUrl(navigation_item?.target_id || '')

    const { params } = useRouteMatch() as any
    const { sequence_id: sequence_identifier } = params
    const sequence = identifySequence(config.sequence_array)(sequence_identifier)

    // console.log({ sequence_url })
    const url =
        navigation_item?.target_model === 'sequence'
            ? sequence_url
            : navigation_item?.target_model === 'sequence_group'
            ? sequence_group_url
            : '/' + navigation_item?.url

    const isOpen = isSelected || (navigation_item.target_model === 'sequence_group' && sequence?.sequence_group_id === navigation_item.target_id)
    return (
        <div>
            <Accordion isOpen={isOpen}>
                <Accordion.Trigger>
                    <Link
                        to={url}
                        style={hovered || isSelected ? nav_item_emphasis_style : nav_item_style}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        className="mx-6 flex items-center text-lg"
                    >
                        <span className="navigation-item-icon-container ">{navigation_item.icon && <Icon icon={navigation_item.icon} />}</span>
                        {navigation_item.label}
                    </Link>
                </Accordion.Trigger>
                {/* <Accordion.Content> */}
                {navigation_item?.target_model === 'sequence_group' && (
                    // {navigation_item?.target_model === 'sequence_group' && (
                    <Accordion.Content>
                        <SequenceGroupNavigation
                            navigation_item={navigation_item}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                        />
                    </Accordion.Content>
                )}
                {selected_navigation_item?.target_model === 'map' && (
                    <Accordion.Content>
                        <MapNavigation
                            map_id={selected_navigation_item?.target_id}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                        />
                    </Accordion.Content>
                )}
                {selected_navigation_item?.target_model === 'gallery' && selected_navigation_item?.target_id_array && (
                    <Accordion.Content>
                        <GalleryNavigation
                            navigation_item={navigation_item}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                        />
                    </Accordion.Content>
                )}
            </Accordion>
        </div>
    )
})
const ULSubMenu = (props: any) => {
    return (
        <div className="navigation-submenu mt-3">
            <ul className="py-6">{props.children}</ul>
        </div>
    )
}
const SequenceGroupNavigation = (props: any) => {
    const { navigation_item, nav_item_style, nav_item_emphasis_style } = props
    const config = useConfig()
    const { sequence_array } = config

    const { params } = useRouteMatch() as any
    const { sequence_id: sequence_identifier } = params
    const sequence = sequence_array.find((s) => s.id === sequence_identifier || s.url === sequence_identifier)

    const click_zone_array = config.click_zone_array.filter((cz) => cz.sequence_id === sequence?.id)
    // const click_zone_type_array = click_zone_array.reduce((acc, cz) => {
    //     config.click_zone_type_array?.length &&
    //         !acc.find((i) => i.id === cz.click_zone_type_id) &&
    //         acc.push(config.click_zone_type_array?.find((czt) => czt.id === cz.click_zone_type_id)!)
    //     return acc
    // }, [] as TypeClickZoneType[])
    const click_zone_type_array = config.click_zone_type_array
    const { selected_click_zone_type_id_array, setState } = useAppContext()

    return (
        <ULSubMenu>
            {config.sequence_array
                ?.filter((s) => s.sequence_group_id === navigation_item?.target_id)
                .map((s) => (
                    <li key={s.id} className="px-6 pt-3">
                        <NavigationSubItem
                            navigation_item={{ label: s.label, target_model: 'sequence', target_id: s.id, url: s.url! }}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                        />
                    </li>
                ))}
            {(click_zone_type_array && (
                <li className="px-6 py-3">
                    {(click_zone_type_array?.length && (
                        <ul>
                            <li className="flex justify-between mt-3 mb-5">
                                <strong className="font-semibold">Filtres</strong>
                                <div
                                    onClick={() => setState((s: TypeAppContext) => ({ ...s, selected_click_zone_type_id_array: [] }))}
                                    className="hover:underline cursor-pointer text-sm"
                                >
                                    Effacer
                                </div>
                            </li>
                            {click_zone_type_array.map((czt) => (
                                <li key={czt.id} className="flex items-center text-sm mb-2">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            const checked = e.target.checked
                                            setState((s: TypeAppContext) => ({
                                                ...s,
                                                selected_click_zone_type_id_array: checked
                                                    ? [...(s.selected_click_zone_type_id_array ?? []), czt.id]
                                                    : (s.selected_click_zone_type_id_array ?? []).filter((czt_id) => czt_id !== czt.id),
                                            }))
                                        }}
                                        checked={selected_click_zone_type_id_array?.includes(czt.id) ?? false}
                                        id={`checkbox-${czt.id}`}
                                        className="h-4 w-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer mr-2"
                                    />
                                    <label htmlFor={`checkbox-${czt.id}`} className="cursor-pointer flex items-center">
                                        <div style={{ width: '1rem', height: '1rem', backgroundColor: czt.color, borderRadius: '1rem' }} className="mr-1" />
                                        {`${czt.label} (${config.click_zone_array.filter((cz) => cz.click_zone_type_id === czt.id).length})`}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    )) ||
                        null}
                </li>
            )) ||
                null}
        </ULSubMenu>
    )
}
const GalleryNavigation = (props: any) => {
    const { navigation_item, nav_item_style, nav_item_emphasis_style } = props
    const config = useConfig()

    return (
        <ULSubMenu>
            {config.gallery_array
                ?.filter((g) => navigation_item?.target_id_array?.includes(g.id))
                .map((g) => (
                    <li key={g.id} className="px-6 pt-3">
                        <NavigationGallerySubItem gallery={g} nav_item_style={nav_item_style} nav_item_emphasis_style={nav_item_emphasis_style} />
                    </li>
                ))}
        </ULSubMenu>
    )
}
type TypeNavigationGallerySubItemProps = {
    gallery: TypeGallery
    nav_item_style?: any
    nav_item_emphasis_style?: any
}
const NavigationGallerySubItem = React.memo(function NavigationGallerySubItem(props: TypeNavigationGallerySubItemProps) {
    const { gallery, nav_item_style, nav_item_emphasis_style } = props
    const [hovered, setHovered] = useState(false)
    const { gallery_id } = useGetParams()

    let url = gallery?.url || gallery?.id

    return (
        <div
            style={hovered || gallery_id === gallery.id ? nav_item_emphasis_style : nav_item_style}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="flex items-center transition duration-500"
        >
            {/* <span className="navigation-item-icon-container inline-block">{gallery.icon && <Icon icon={gallery.icon} />}</span> */}
            <Link to={url} className="ml-8">
                {gallery.label}
            </Link>
        </div>
    )
})
type TypeNavigationSubItemProps = {
    navigation_item: TypeNavigationItem
    nav_item_style?: any
    nav_item_emphasis_style?: any
}
const NavigationSubItem = React.memo(function NavigationSubItem(props: TypeNavigationSubItemProps) {
    const { navigation_item, nav_item_style, nav_item_emphasis_style } = props
    const [hovered, setHovered] = useState(false)
    const config = useConfig()
    const { sequence_array } = config
    const sequence_url = useGetSequenceFullUrl(navigation_item?.target_id || '')
    const sequence_group_url = useGetSequenceGroupFullUrl(navigation_item?.target_id || '')
    const { sequence_id: sequence_identifier, image } = useGetParams()
    const sequence = sequence_array.find((s) => s.id === sequence_identifier || s.url === sequence_identifier)

    let url =
        navigation_item?.target_model === 'sequence'
            ? sequence_url
            : navigation_item?.target_model === 'sequence_group'
            ? sequence_group_url
            : '/' + navigation_item?.url

    url = image ? url + '/' + image : url

    const history = useHistory()

    return (
        <div
            style={hovered || sequence?.id === navigation_item?.target_id ? nav_item_emphasis_style : nav_item_style}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="flex items-center transition duration-500"
        >
            <span className="navigation-item-icon-container inline-block">{navigation_item.icon && <Icon icon={navigation_item.icon} />}</span>
            {/* <Link to={url}> */}
            <input
                type="radio"
                id={`radio-sequence-${navigation_item?.target_id}`}
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 cursor-pointer mr-2"
                name={`sequence-${sequence?.sequence_group_id}`}
                onChange={(e) => history.push(url)}
                checked={navigation_item?.target_id === sequence_identifier}
            />
            <label htmlFor={`radio-sequence-${navigation_item?.target_id}`} className="cursor-pointer">
                {navigation_item.label}
            </label>
            {/* </Link> */}
        </div>
    )
})

const MapNavigation = (props: any) => {
    const { map_id } = props
    const config = useConfig()
    const { location_group_array } = config
    const map = config.map_array?.find((m) => m.id === map_id)
    const lg_array = map?.location_array
        ?.reduce((acc, location: TypeLocation) => {
            if (location.location_group_id && !acc.includes(location.location_group_id)) acc.push(location.location_group_id)
            return acc
        }, [] as string[])
        .map((lgid) => location_group_array?.find((lg) => lg.id === lgid))

    const pin = map?.location_array?.find((l) => l.type === 'main')
    return (
        <ULSubMenu>
            {/* {(pin && (
                <li className="px-6 py-3">
                    <MapNavigationItem location={pin} nav_item_style={props.nav_item_style} nav_item_emphasis_style={props.nav_item_emphasis_style} />
                </li>
            )) ||
                null} */}
            {lg_array?.map((lg) => (
                <li key={lg?.id} className="px-6 py-3">
                    {(lg?.label && (
                        <div className="py-2">
                            <strong>{lg?.label}</strong>
                        </div>
                    )) ||
                        null}
                    <ul className="border-l-2 border-gray-300">
                        {map?.location_array
                            ?.filter((l) => l.location_group_id === lg?.id)
                            .map((l, index) => (
                                <li key={index} className="px-6 py-2 text-sm">
                                    <MapNavigationItem
                                        location={l}
                                        nav_item_style={props.nav_item_style}
                                        nav_item_emphasis_style={props.nav_item_emphasis_style}
                                    />
                                </li>
                            ))}
                    </ul>
                </li>
            ))}
        </ULSubMenu>
    )
}
const MapNavigationItem = (props: { location: TypeLocation; nav_item_style: any; nav_item_emphasis_style: any }) => {
    const { location, nav_item_style, nav_item_emphasis_style } = props
    const { setState } = useAppContext()
    const [hovered, setHovered] = useState(false)
    return (
        <div
            className="flex cursor-pointer"
            onClick={() => setState((s: any) => ({ ...s, location: { ...location } }))}
            style={hovered ? nav_item_emphasis_style : nav_item_style}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <span className="navigation-item-icon-container inline-block">
                <LocationIcon {...location} />
            </span>
            {location.label}
        </div>
    )
}

export default NavigationContainer
