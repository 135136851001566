import React, { useMemo, useState } from 'react'
import { DEFAULT_NAV_ITEM_COLOR, DEFAULT_NAV_ITEM_TEXT_COLOR, DEFAULT_NAV_ITEM_EMPHASIS_COLOR, DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR } from '../constants'
import { TypeSequenceButton } from '../types'
import { useConfig } from './hooks'

const SequenceButtons = React.memo(function SequenceButtons(props: any) {
    const { sequence_button_array = [], color_array = [] } = useConfig()

    const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    const nav_item_emphasis_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis'), [])
    const nav_item_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_text'), [])
    const nav_item_emphasis_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis_text'), [])
    const nav_item_style = {
        backgroundColor: nav_item_color?.color ?? DEFAULT_NAV_ITEM_COLOR,
        color: nav_item_text_color?.color ?? DEFAULT_NAV_ITEM_TEXT_COLOR,
    }
    const nav_item_emphasis_style = {
        backgroundColor: nav_item_emphasis_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_COLOR,
        color: nav_item_emphasis_text_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR,
    }

    return sequence_button_array ? (
        <div id="sequence-button-container" className="fixed bottom-0 left-0 pl-4 mb-4">
            {sequence_button_array.map((sb) => (
                <SequenceButton key={sb.id} sequence_button={sb} nav_item_style={nav_item_style} nav_item_emphasis_style={nav_item_emphasis_style} />
            ))}
        </div>
    ) : null
})
const SequenceButton = React.memo(function SequenceButton(props: { sequence_button: TypeSequenceButton; nav_item_style: any; nav_item_emphasis_style: any }) {
    const { sequence_button, nav_item_style, nav_item_emphasis_style } = props
    const [hovered, setHovered] = useState(false)

    return (
        <a
            className="p-3 inline-block cursor-pointer mr-4"
            href={sequence_button.href}
            style={hovered ? nav_item_emphasis_style : nav_item_style}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {sequence_button.label}
        </a>
    )
})

export default SequenceButtons
