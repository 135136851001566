import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import '../styles/map.css'
import { LocationPin, Location } from './LocationMarkers'
import { useAdmin, useConfig } from './hooks'
import { dummyFn } from '../constants'
import useAppContext from '../hooks/useAppContext'
import { TypeAppContext } from '../contexts'

const GOOGLE_MAPS_API_KEY = `AIzaSyDJ1mxkVSEPolLy28IxeWJeKQiAkhuxq54`
const MAPBOX_TOKEN = `pk.eyJ1IjoiYWxleGFuZHJlZGV2ZWF1eCIsImEiOiJjam5hajMwOXoxeGVxM3ZzN3QwZXZja3BjIn0.uJ9MJ7_3DAuqZ4ADX0t8oA`

const handleApiLoaded = (map: any, maps: any) => {
    console.log({ map }, { maps })
}

const DEFAULT_MAPTYPE = 'roadmap' // 'roadmap', 'hybrid', satellite', 'terrain'
const createMapOptions = function (maps: any) {
    return {
        //     panControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.DEFAULT,
            position: maps.ControlPosition.TOP_RIGHT,
        },
        //     scrollwheel: true,
        fullscreenControl: false,
        mapTypeId: DEFAULT_MAPTYPE,
        //     styles: [{ stylers: [{ saturation: 50 }, { gamma: 1 }, { lightness: 8 }, { visibility: 'on' }] }],
        //     // styles: [{ stylers: [{ saturation: -50 }, { gamma: 1 }, { lightness: 4 }, { visibility: 'on' }] }],
    }
}

type TypeMapProps = { id: string }
const GoogleMaps = React.memo(function GoogleMaps(props: TypeMapProps) {
    const { id } = props
    const { map_array } = useConfig()
    const admin = useAdmin()

    const map = map_array?.find((m) => m.id === id)

    const [gmap, setGMap] = useState<any>(null)
    // console.log(gmap)

    const { location, map_type, setState } = useAppContext()

    useEffect(() => {
        location && gmap?.setCenter({ lat: location.lat, lng: location.lng })
    }, [location])
    const [zoom, setZoom] = useState<any>(map?.zoomLevel ?? 16)

    useEffect(() => setState((s: TypeAppContext) => ({ ...s, map_type: DEFAULT_MAPTYPE })), [])

    return (
        <div className="map w-full h-full">
            <div className="google-map">
                {(map && (
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, language: 'fr', region: 'fr' }}
                        defaultCenter={map}
                        defaultZoom={map.zoomLevel}
                        yesIWantToUseGoogleMapApiInternals
                        onClick={(admin.enabled && (({ x, y, lat, lng, event }) => console.log({ lat, lng }, event))) || dummyFn}
                        onGoogleApiLoaded={({ map, maps }) => setGMap(map)}
                        onZoomAnimationEnd={setZoom}
                        options={createMapOptions}
                        onMapTypeIdChange={(map_type) => setState((s: TypeAppContext) => ({ ...s, map_type }))}
                    >
                        {map.location_array?.map((l, index) => (
                            <Location {...l} zoom={zoom} key={index} />
                        ))}
                    </GoogleMapReact>
                )) ||
                    null}
            </div>
        </div>
    )
})

export default GoogleMaps
