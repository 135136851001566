import React, { SyntheticEvent } from 'react'
import { IMAGE_HOST, IMAGE_PATH } from '../constants'
import useConfig from '../hooks/useConfig'

type TypeResize = {
    width: string | number
    height: string | number
    format?: 'cover' | 'contain'
}
type TypeImageProps = {
    src: string
    alt?: string
    className?: string
    style?: any
    resize?: TypeResize
    onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
    onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
    width?: string | number
    height?: string | number
    onClick?: (event: any) => void
}

export const Polaroid = (props: any) => <Image {...props} className={'bg-white p-2 shadow-md border'} />
export const getResizedSrc = ({ src, resize }: { src: string; resize: TypeResize }) => {
    const { width, height, format = 'contain' } = resize || {}
    if (width || height) {
        const extension = src.substring(src.lastIndexOf('.') + 1)
        const base = src.lastIndexOf('/') ? src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('.')) : src.substring(0, src.lastIndexOf('.'))
        const path = src.lastIndexOf('/') ? src.substring(0, src.lastIndexOf('/')) : '/'

        src = `${path}/r/${base}-${format}-${width}-${height}.${extension}`
    }
    return src
}
const Image = React.memo(function Image(props: TypeImageProps) {
    let { src, alt, className, style, resize, onLoad, onError, width, height, onClick } = props
    if (resize) {
        src = getResizedSrc({ src, resize })
    }
    if (src.substring(0, IMAGE_PATH.length) === IMAGE_PATH) {
        src = src.substring(IMAGE_PATH.length)
    }

    return (
        <img
            src={IMAGE_HOST + IMAGE_PATH + src}
            alt={alt || ''}
            className={className}
            style={style}
            onLoad={onLoad}
            onError={onError}
            width={width}
            height={height}
            onClick={onClick}
        />
    )
})

export default Image
