import React from 'react'
import { useHistory } from 'react-router-dom'
import useAdmin from '../hooks/useAdmin'
import useConfig from '../hooks/useConfig'
import { TypeImageMenuZone } from '../types'
import LazySVG from './LazySVG'

type TypeImageMenuZoneProps = {
    image_menu_zone: TypeImageMenuZone
}

const ImageMenuZone = React.memo(function ImageMenuZone(props: TypeImageMenuZoneProps) {
    const { image_menu_zone } = props
    const { left, top, width, height, id, target } = image_menu_zone
    const { edit, setSelected } = useAdmin()
    const { base, static_page_array = [], click_zone_array = [] } = useConfig()
    const history = useHistory()
    const click_zone = click_zone_array.find((cz) => cz.image_menu_zone_id === image_menu_zone.id)
    const goToTarget = () => {
        if (target?.sequence_group_id) {
            history.push(`${base}${target.sequence_group_id}`)
        } else if (target?.static_page_id) {
            const static_page = static_page_array.find((sp) => sp.id === target.static_page_id)
            window.location.href = `${base}${static_page?.path}`
        }
    }
    return click_zone ? (
        <div data-zone-id={click_zone.id}>
            <LazySVG
                src={`click_zone/${click_zone.id}/0000.svg`}
                className={'cursor-pointer absolute pointer-events-none max-w-full max-h-full w-full h-full object-contain click-zone'}
                visible={true}
                onClick={goToTarget}
            />
        </div>
    ) : (
        (void 0 !== left && void 0 !== top && width && height && (
            <div
                onClick={(edit && (() => setSelected({ type: 'image-menu-zone', ...image_menu_zone }))) || goToTarget}
                style={{
                    position: 'absolute',
                    left: left + '%',
                    top: top + '%',
                    width: width + '%',
                    height: height + '%',
                    backgroundColor: image_menu_zone.color,
                }}
                className={edit ? 'cursor-pointer bg-blue-800 opacity-0 hover:blue-400 hover:opacity-0 shadow' : 'cursor-pointer hover:opacity-0 opacity-0'}
            >
                {/* {image_menu_zone.label} */}
            </div>
        )) ||
            null
    )
})

export default ImageMenuZone
