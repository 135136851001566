import { flow } from 'fp-ts/lib/function'
import React, { useRef, useState } from 'react'
import { TypeClickZone, TypeConfig, TypeFile, TypeSequence } from '../types'
import Button from './Button'
import { useApi, useConfig, useData, useInput, useSelect } from './hooks'
import Input from './Input'
import Page from './Page'
import { pipe } from 'fp-ts/lib/function'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'

const DELIMITER_ARRAY = ['-', '_', ' ']

const trim = (str: string) => str.trim()
const getFileInfo = (str: string) => {
    const i = str.lastIndexOf('.')
    return { filename: str.slice(0, i), extension: str.slice(i + 1) }
}
const prop =
    (prop: string) =>
    (obj: any): any =>
        obj?.[prop]

const findPlace =
    ({ sequence_array, click_zone_array }: { sequence_array: TypeSequence[]; click_zone_array: TypeClickZone[] }) =>
    (str: string) => {
        const sequence = sequence_array.find((s) => str.includes(s.label))
        const click_zone = click_zone_array.find((s) => str.includes(s.label))
        return { sequence, click_zone }
    }

const Import = React.memo(function Import() {
    // const importApi = useApi()
    const config = pipe(useConfig(), configFromFileIdToFileIdArray)
    const { sequence_array, click_zone_array } = config
    const [configCandidate, setConfigCandidate] = useState<TypeConfig>({} as TypeConfig)
    const importData = useData<any>({ url: 'import-list' }, [])
    console.log({ importData })

    const importApi = useApi()

    const candidates = importData.data?.file_array?.map(flow(trim, getFileInfo, prop('filename'), trim))
    console.log({ candidates }, { sequence_array }, { click_zone_array })

    // sequence_array.map(flow(prop('label'), findMatch()))
    const findInConfig = findPlace({ sequence_array, click_zone_array })
    // const can = candidates?.map(findInConfig)
    // console.log({ can })

    const analyze = () => {
        if (importData.isLoading) return
        setConfigCandidate((conf: TypeConfig) => {
            const file_array: TypeFile[] =
                importData.data?.file_array?.map((file: string) => {
                    const f = (config.file_array || []).find((_f) => _f.src === file)
                    return f || { id: file, label: file, src: file, original_src: file }
                }) || []
            return {
                ...config,
                file_array,
                click_zone_array: config.click_zone_array
                    ? config.click_zone_array.map((cz) => {
                          const f_array = file_array.filter((f) => findInConfig(f.src)?.click_zone?.id === cz.id)
                          return f_array
                              ? {
                                    ...cz,
                                    file_id_array: [
                                        ...(cz.file_id_array || (cz.file_id && [cz.file_id]) || []).filter((fid) => !f_array.find((f) => f.id === fid)),
                                        ...f_array.map((f) => f.id),
                                    ],
                                }
                              : cz
                      })
                    : [],
            }
        })
    }

    console.log({ configCandidate })

    // list of things to apply to the label
    // remove sequence name
    // include any other label than the click zone label

    const removeSequenceNameCheckbox = useInput(true)
    const keepOtherLabelsCheckbox = useInput(true)

    const moveFile = (sourceIndex: number, targetIndex: number) => {
        console.log(
            configCandidate?.file_array.reduce((acc: TypeFile[], f: TypeFile, index: number) => {
                if (index !== sourceIndex) {
                    if (index === targetIndex) {
                        acc.push(configCandidate.file_array[sourceIndex])
                    }
                    acc.push(f)
                }
                return acc
            }, [])
        )
        setConfigCandidate((c) => ({
            ...c,
            file_array: c.file_array.reduce((acc: TypeFile[], f: TypeFile, index: number) => {
                if (index !== sourceIndex) {
                    if (index === targetIndex) {
                        acc.push(c.file_array[sourceIndex])
                    }
                    acc.push(f)
                }
                return acc
            }, []),
        }))
    }

    let file_index = 0

    return (
        <>
            <Page>
                {(!importData.isLoading && <div>{importData.data?.sequence_array?.length ?? '0'} sequences to import</div>) || null}
                {(!importData.isLoading && <div>{importData.data?.click_zone_array?.length ?? '0'} zones to import</div>) || null}
                {(!importData.isLoading && <div>{importData.data?.file_array?.length ?? '0'} files to import</div>) || null}
                <h2 className="mt-2">Options</h2>
                <Input type="checkbox" {...removeSequenceNameCheckbox.bind} className="form-checkbox" label="Remove sequence name" />
                <Input type="checkbox" {...keepOtherLabelsCheckbox.bind} className="form-checkbox" label="Keep other labels" />
                <Button onClick={analyze}>Analyze</Button>
                <h2 className="mt-2">Unassigned files</h2>
                <ul>
                    {configCandidate?.file_array
                        ?.filter((f) => !configCandidate?.click_zone_array.find((cz) => cz.file_id_array?.includes(f.id)))
                        .map((f, index) => (
                            <div key={f.id}>
                                <UnassignedFileRow
                                    configCandidate={configCandidate}
                                    setConfigCandidate={setConfigCandidate}
                                    file_id={f.id}
                                    index={index}
                                    moveFile={moveFile}
                                />
                            </div>
                        ))}
                </ul>
                <ul>
                    {configCandidate?.sequence_array?.map((s) => (
                        <li key={s.id}>
                            <div>{s.label}</div>
                            {configCandidate.click_zone_array
                                ?.filter((cz) => cz.sequence_id === s.id)
                                .map((cz) => {
                                    return (
                                        <div key={cz.id} className="ml-6">
                                            <div>{cz.label}</div>
                                            {cz?.file_id_array?.map((fid) => {
                                                const jsx = (
                                                    <div key={fid} className="ml-6">
                                                        <File configCandidate={configCandidate} file_id={fid} index={file_index} moveFile={moveFile} />
                                                    </div>
                                                )
                                                file_index++
                                                return jsx
                                            })}
                                        </div>
                                    )
                                })}
                        </li>
                    ))}
                </ul>
                <Button onClick={() => importApi.post('import_file_array', configCandidate)}>Import Files</Button>
                {/* <ul>
                    {configCandidate?.file_array?.map((file) => (
                        <li key={file.id} className="mt-2">
                            {file.id}
                            <div>
                                <small>
                                    <SequenceCandidate configCandidate={configCandidate} file={file} />
                                </small>
                            </div>
                            <div>
                                <small>
                                    <ClickZoneCandidate configCandidate={configCandidate} file={file} />
                                </small>
                            </div>
                        </li>
                    ))}
                </ul> */}
                {/* <Button
                    onClick={() => {
                        importApi.post('import')
                    }}
                    {...importApi.bind}
                >
                    Import
                </Button> */}
            </Page>
        </>
    )
})

const configFromFileIdToFileIdArray = (conf: TypeConfig) => {
    return {
        ...conf,
        click_zone_array: (conf?.click_zone_array || []).map((cz) => {
            return cz.file_id ? { ...cz, file_id_array: [cz.file_id] } : cz
        }),
    }
}

interface DragItem {
    index: number
    id: string
    type: string
}
interface XYCoord {
    x: number
    y: number
}

const File = (props: { configCandidate: TypeConfig; file_id: string; index: number; moveFile: Function }) => {
    const { configCandidate, file_id, index, moveFile } = props
    const file = configCandidate?.file_array?.find((f) => f.id === file_id)
    const ref = useRef<HTMLDivElement>()
    const [{ opacity }, drag] = useDrag(
        () => ({
            type: 'file',
            item: { text: file?.id, id: file?.id, index },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        []
    )
    const [{ handlerId }, drop] = useDrop({
        accept: 'file',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveFile(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    drag(drop(ref))
    return (
        <div ref={ref as any} style={{ opacity }} data-handler-id={handlerId}>
            {file?.id}
        </div>
    )
}
const toOptions = (i: any) => ({ ...i, value: void 0 !== i.value ? i.value : i.id })

const UnassignedFileRow = (props: { configCandidate: TypeConfig; setConfigCandidate: Function; file_id: string; index: number; moveFile: Function }) => {
    const { configCandidate, setConfigCandidate, file_id, index, moveFile } = props
    const assignSelect = useSelect()
    return (
        <div className="flex items-center">
            <File configCandidate={configCandidate} file_id={file_id} index={index} moveFile={moveFile} />{' '}
            <Input
                type="select"
                {...assignSelect.bind}
                options={configCandidate?.sequence_array?.map((s) => ({
                    ...s,
                    label: 'Sequence ' + s.label,
                    options: configCandidate?.click_zone_array?.filter((cz) => cz.sequence_id === s.id).map(toOptions),
                }))}
                className="w-64 mx-2"
            />
            <Button
                color="primary"
                className="ml-2"
                disabled={!assignSelect.value}
                onClick={() => {
                    console.log(assignSelect.value)
                    setConfigCandidate((c: TypeConfig) => ({
                        ...c,
                        click_zone_array: c.click_zone_array.map((cz) =>
                            cz.id === assignSelect.value?.id ? { ...cz, file_id_array: [...(cz.file_id_array || []), file_id] } : cz
                        ),
                    }))
                }}
            >
                Set
            </Button>
        </div>
    )
}

const ClickZoneCandidate = (props: { configCandidate: TypeConfig; file: TypeFile }) => {
    const { configCandidate, file } = props
    const cz = configCandidate?.click_zone_array?.find((cz) => cz.file_id_array?.includes(file.id))
    return (cz && <>Zone: {cz.label}</>) || <div className="text-red-500">No Zone Found</div>
}
const SequenceCandidate = (props: { configCandidate: TypeConfig; file: TypeFile }) => {
    const { configCandidate, file } = props
    const cz = configCandidate?.click_zone_array?.find((cz) => cz.file_id_array?.includes(file.id))
    const sequence = configCandidate?.sequence_array?.find((s) => s.id === cz?.sequence_id)
    return (sequence && <>Sequence: {sequence.label}</>) || <div className="text-red-500">No Sequence Found</div>
}

export default Import
