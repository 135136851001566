import React, { useState } from 'react'
import { API_URL } from '../constants'
import { useId } from './hooks'
import Icon from './Icon'
import Spinner from './Spinner'

type TypeFileInputProps = any

const FileInput = React.memo(function FileInput(props: TypeFileInputProps) {
    const { payload, onSuccess, ...rest } = props
    const id = useId()
    const [isUploading, setIsUploading] = useState(false)
    const onChange = (e: any) => {
        console.log(Array.from(e.target.files))
        const formData = new FormData()
        Array.from(e.target.files).map((f: any) => {
            formData.append(f.name, f)
        })
        payload && Object.keys(payload).map((key: string) => formData.append(key, payload[key]))
        setIsUploading(true)
        fetch(API_URL + 'upload-images', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                setIsUploading(false)
                console.log('File upload finished')
                onSuccess?.(r)
            })
    }
    return (
        <div className="flex">
            <input id={id} type="file" {...rest} onChange={onChange} />
            <label htmlFor={id} className={'btn btn-default btn-xs ' + (props.className || '') + (isUploading ? ' pointer-events-none disabled' : '')}>
                <span className="flex items-center justify-center">
                    {isUploading ? (
                        <>
                            <Spinner className="mr-2" /> Loading
                        </>
                    ) : (
                        <>
                            <Icon name="upload" className="mr-1" />
                            Upload
                        </>
                    )}
                </span>
            </label>
        </div>
    )
})

export default FileInput
