import React, { useEffect, useState, useRef, useMemo } from 'react'
import { flow } from 'fp-ts/lib/function'
import { useHistory } from 'react-router-dom'
import useConfig from '../hooks/useConfig'
import useGetParams from '../hooks/useGetParams'
import Image, { getResizedSrc } from './Image'
import LazySVG from './LazySVG'
import { useAdmin, useApi, useDimensions, useInput } from './hooks'
import DetailsPane from './DetailsPane'
import Button from './Button'
import Input from './Input'
import Compass from './Compass'
import { useWhyDidYouUpdate } from 'use-why-did-you-update'
import { IMAGE_HOST, IMAGE_PATH } from '../constants'
import { RAF } from '../modules/helpers'
import useDisplayContext from '../hooks/useDisplayContext'
import { TypeClickZoneType } from '../types'
import useAppContext from '../hooks/useAppContext'
import { none } from 'fp-ts/lib/Option'

type TypeSequenceProps = {
    sequence_id?: string
}

const dummyObject = {}

// const resizeObj = { width: 576, height: 324 }
// const resizeObj = { width: 1152, height: 648 }
const imageSizeArray = [
    { width: 288, height: 162, className: 'image-sequence-blur-4' },
    // { width: 576, height: 324, className: 'image-sequence-blur-2' },
    { width: 1152, height: 648, className: 'image-sequence-default' },
]

const fetching_triggered: any = {}

// @TODO
// const image_thread_max = 3

const withOffsetFactory =
    (start: number) =>
    (end: number) =>
    (_offset: number = 0) =>
    (number: number) => {
        const offset = Number(_offset || 0)
        const candidate = number + offset
        if (offset === 0) {
            return number
        } else if (candidate < start) {
            const diff = start - candidate
            return end + 1 - diff
        } else if (candidate > end) {
            const diff = candidate - end
            return start - 1 + diff
        }
        return candidate
    }

const evalImageCandidate = ({
    candidateImage,
    sequence_image_start,
    sequence_image_end,
}: {
    candidateImage: number
    sequence_image_start: number | undefined
    sequence_image_end: number | undefined
}) => {
    const image_start = Number(sequence_image_start) || 0
    const image_end = Number(sequence_image_end) || 1
    if (candidateImage > image_end) {
        candidateImage -= image_end
    } else if (candidateImage < image_start) {
        candidateImage = image_end + candidateImage + 1
    }
    return { candidateImage }
}

const Sequence = React.memo(function Sequence(props: TypeSequenceProps) {
    const { admin_overlay, setAdmin } = useAdmin()
    const config = useConfig()
    const {
        sequence_group_id,
        image,
        sequence_id,
        // zone,
        sequence_group,
        sequence,
        sequence_array,
        sequence_overlay,
        start,
        end,
        reverse_rotation,
        compass,
        zone_stroke_color,
        zone_stroke_width,
        zone_color,
        zone_color_emphasis,
    } = useGetParams(props)
    const history = useHistory()

    // console.log({ sequence_group_id, image, sequence_id, zone, sequence_group, sequence, sequence_array, start, end, reverse_rotation })

    /**
     *
     * 74 = 15
     *
     * 89 = 0
     *
     * 0  = 89
     *
     * 1 = 88
     *
     * 5 = 84
     *
     * 6 = 83
     *
     *
     *
     */
    const [tick, setTick] = useState<any>(null)
    const [imageSizeIndex, setImageSizeIndex] = useState<number>(config.progressive_loading ? 0 : imageSizeArray.length - 1)
    const [currentImage, setCurrentImage] = useState<number>(Number(image ?? 0))
    const [mouse_starting_position, setMouseStartingPosition] = useState(0)
    const [offset_image, setOffsetImage] = useState(Number(image ?? 0))
    const target = useRef<any>(null)
    !target.current && (target.current = document.querySelector('#mouse-target'))
    const [mouseTargetRef, dimensions] = useDimensions({ liveMeasure: true })

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedZone, setSelectedZone] = useState<any>(null)
    const [tooltipPosition, setTooltipPosition] = useState<any>(null)

    reverse_rotation && console.log({ currentImage }, { end }, end - currentImage)
    const [loadedImageArray, setLoadedImageArray] = useState<any[]>(
        []
        // imageSizeArray.map((is, index) => ({ sequence_id, imageSizeIndex: index, image_nb: sequence.offset || 0 }))
    )
    const [prefetchedImageArray, setPrefetchedImageArray] = useState<any[]>(
        []
        // imageSizeArray.map((is, index) => ({ sequence_id, imageSizeIndex: index, image_nb: sequence.offset || 0 }))
    )

    const withOffset = withOffsetFactory(Number(start))(Number(end))(
        (sequence?.offset ? Number(sequence?.offset) : 0) + (reverse_rotation ? -(currentImage * 2) - 1 : 0)
    )
    // console.log(imageSizeIndex, { prefetchedImageArray }, { loadedImageArray })
    // const withOffset = useMemo(() => {
    //     reverse_rotation && console.log({ currentImage }, { end }, end - currentImage)
    //     return withOffsetFactory(Number(start))(Number(end))(
    //         (sequence?.offset ? Number(sequence?.offset) : 0) + (reverse_rotation ? -(currentImage * 2) - 1 : 0)
    //     )
    // }, [start, end, sequence?.offset, sequence?.id, currentImage])

    useEffect(() => {
        console.log('sequence changed')

        selectedZone && setSelectedZone(null)
        if (currentImage < start) {
            goToImage(start)
        } else if (currentImage > end) {
            goToImage(end)
        }
    }, [sequence_id])

    useEffect(() => {
        // console.log('useEffect 2')
        setAdmin((c: any) => ({ ...c, displaying_sequence: true }))
        return () => {
            setAdmin((c: any) => ({ ...c, displaying_sequence: false }))
        }
    }, [])

    const previousImage = () => {
        if (reverse_rotation) {
            return currentImage === start ? end : currentImage - 1
        } else {
            return currentImage === end ? start : currentImage + 1
        }
    }
    const nextImage = () => {
        if (reverse_rotation) {
            return currentImage === end ? start : currentImage + 1
        } else {
            return currentImage === start ? end : currentImage - 1
        }
    }
    const goToImage = (targetImage: number) => {
        setCurrentImage(targetImage)
        updateImageInURL(targetImage)
    }
    const goToNextImage = flow(nextImage, goToImage)
    const goToPreviousImage = flow(previousImage, goToImage)

    const sequence_position = sequence_array.findIndex((s) => s.id === sequence_id)
    const up_possible = sequence_position > 0
    const down_possible = sequence_position < sequence_array.length - 1

    const upSequence = sequence_array[sequence_position - 1]
    const downSequence = sequence_array[sequence_position + 1]
    const withOffsetUp = withOffsetFactory(upSequence?.image_start ? Number(upSequence?.image_start) : Number(start))(
        upSequence?.image_end ? Number(upSequence?.image_end) : Number(end)
    )(upSequence?.offset ? Number(upSequence?.offset) : 0)

    const withOffsetDown = withOffsetFactory(downSequence?.image_start ? Number(downSequence?.image_start) : Number(start))(
        downSequence?.image_end ? Number(downSequence?.image_end) : Number(end)
    )(downSequence?.offset ? Number(downSequence?.offset) : 0)

    const handleKeyDown = (event: any) => {
        // console.log(event.key)
        switch (event.key.toLowerCase()) {
            case 'arrowleft':
                if (reverse_rotation) {
                    goToNextImage()
                } else {
                    goToPreviousImage()
                }
                storeOffsetImage()
                break
            case 'arrowright':
                if (reverse_rotation) {
                    goToPreviousImage()
                } else {
                    goToNextImage()
                }
                storeOffsetImage()
                break
            case 'arrowup':
                // !config.navigation_array?.length &&
                up_possible &&
                    history.push(
                        `${config.base}${sequence_group?.url || sequence_group?.id}/${
                            sequence_array[sequence_position - 1]?.url || sequence_array[sequence_position - 1]?.id
                        }/${String(currentImage).padStart(4, '0')}`
                    )
                break
            case 'arrowdown':
                // !config.navigation_array?.length &&
                down_possible &&
                    history.push(
                        `${config.base}${sequence_group?.url || sequence_group?.id}/${
                            sequence_array[sequence_position + 1]?.url || sequence_array[sequence_position + 1]?.id
                        }/${String(currentImage).padStart(4, '0')}`
                    )
                break
            default:
                break
        }
    }
    const updateImageInURL = (targetImage: string | Number) =>
        history.push(`${config.base}${sequence_group?.url || sequence_group?.id}/${sequence.url || sequence.id}/${String(targetImage).padStart(4, '0')}`)
    console.log()
    const handleNewCoords = (e: any) => {
        e.preventDefault()
        const cursor_pos_x = e.pageX || e.touches?.[0]?.clientX
        const cursor_pos_y = e.pageY || e.touches?.[0]?.clientY
        if (target.current) {
            const coords = target.current.getBoundingClientRect()
            const one_image_width = coords.width / (sequence?.image_end || end)
            let msp = 0
            setMouseStartingPosition((m) => {
                msp = m
                return m
            })
            let offset = 0
            setOffsetImage((o) => {
                offset = o
                return o
            })
            let cI = 0
            setCurrentImage((i) => {
                cI = i
                return i
            })
            let mouse_diff = (msp - (cursor_pos_x || 0)) * (reverse_rotation ? 1 : 1)
            if (cursor_pos_y < dimensions.height / 2) {
                mouse_diff = mouse_diff * -1
            }
            const image_diff = Math.round(mouse_diff / one_image_width)

            let { candidateImage } = evalImageCandidate({ candidateImage: offset + image_diff, sequence_image_start: start, sequence_image_end: end })
            if (candidateImage !== cI) {
                setCurrentImage(candidateImage)
                updateImageInURL(candidateImage)
            }
        }
    }

    const storeOffsetImage = () => {
        let cI = 0
        setCurrentImage((i) => {
            cI = i
            return i
        })
        setOffsetImage(cI)
    }

    const listenToMove = (e: any) => {
        // console.log('listenToMove')
        // e.preventDefault()
        setMouseStartingPosition(e.pageX || e.touches[0].clientX)
        target.current.addEventListener('mousemove', handleNewCoords)
        target.current.addEventListener('touchmove', handleNewCoords)
    }
    const stopListeningToMove = () => {
        // console.log('stopListeningToMove')
        target.current.removeEventListener('mousemove', handleNewCoords)
        target.current.removeEventListener('touchmove', handleNewCoords)
        storeOffsetImage()
    }

    useEffect(() => {
        // console.log('useEffect 3')
        if (target.current) {
            target.current.addEventListener('touchstart', listenToMove)
            target.current.addEventListener('touchend', stopListeningToMove)
            target.current.addEventListener('mousedown', listenToMove)
            target.current.addEventListener('mouseup', stopListeningToMove)
            return () => {
                target.current.removeEventListener('touchstart', listenToMove)
                target.current.removeEventListener('touchend', stopListeningToMove)
                target.current.removeEventListener('mousedown', listenToMove)
                target.current.removeEventListener('mouseup', stopListeningToMove)
            }
        }
    }, [target.current, start, end, reverse_rotation, dimensions, sequence_id])

    useEffect(() => {
        // console.log('useEffect 4')
        // RAF(() => {})
        document.addEventListener('keydown', handleKeyDown)
        config.details_pane_position !== 'tooltip' && setIsOpen(false)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [config, sequence, currentImage])

    const [image_url_array, image_overlay_url_array] = useMemo(() => {
        const url_array: any[] = []
        for (let i = start; i <= end; i++) {
            url_array.push({ url: `sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`, image_nb: i })
        }
        const overlay_url_array: any[] = []
        if (typeof sequence_overlay?.image_start !== 'undefined' && sequence_overlay?.image_end) {
            for (let i = sequence_overlay?.image_start; i <= sequence_overlay?.image_end; i++) {
                overlay_url_array.push({ url: `sequence_overlay/${sequence_overlay.id}/${String(i).padStart(4, '0')}.png`, image_nb: i })
            }
        }
        return [url_array, overlay_url_array]
    }, [sequence_id, start, end])

    useEffect(() => {
        // we MUST check if there is enough below `imageSizeIndex` to allow fetching
        let belowImageSizeIsLoaded = true
        if (imageSizeIndex > 0) {
            for (let i = 0; i <= imageSizeIndex; i++) {
                !prefetchedImageArray.find((pi) => pi.sequence_id === sequence_id && pi.imageSizeIndex === imageSizeIndex - 1) &&
                    (belowImageSizeIsLoaded = false)
            }
        }
        if (belowImageSizeIsLoaded) {
            // console.log('YEP')
            for (let i = start; i <= end; i++) {
                const foundInPrefetched = prefetchedImageArray
                    .filter((pi) => pi.image_nb === i && pi.sequence_id === sequence.id)
                    .reduce((acc, ip) => {
                        return acc === dummyObject || ip.imageSizeIndex > acc.imageSizeIndex ? ip : acc
                    }, dummyObject)
                const performanceEntry = window.performance.getEntriesByName(
                    getResizedSrc({
                        src: `${IMAGE_HOST}${IMAGE_PATH}sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`,
                        resize: imageSizeArray[0],
                    })
                )[0] as any
                const declareAsPrefetched = (image_nb: number) => {
                    setPrefetchedImageArray((arr) =>
                        arr.find((pi) => pi.image_nb === image_nb && pi.sequence_id === sequence_id && pi.imageSizeIndex === imageSizeIndex)
                            ? arr
                            : [...arr, { imageSizeIndex, sequence_id, image_nb: image_nb }]
                    )
                }
                // console.log({ performanceEntry }, performanceEntry?.transferSize)
                // console.log(
                //     'HERE FETCHING IMAGE' + i,
                //     getResizedSrc({
                //         src: `${IMAGE_HOST}${IMAGE_PATH}sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`,
                //         resize: imageSizeArray[foundInPrefetched.imageSizeIndex || 0],
                //     })
                // )
                if (performanceEntry?.transferSize) {
                    declareAsPrefetched(i)
                } else {
                    fetching_triggered[sequence_id + '-' + imageSizeIndex + '-' + i] && declareAsPrefetched(i)
                    !fetching_triggered[sequence_id + '-' + imageSizeIndex + '-' + i] &&
                        fetch(
                            getResizedSrc({
                                src: `${IMAGE_HOST}${IMAGE_PATH}sequence/${sequence_id}/${String(i).padStart(4, '0')}.jpg`,
                                resize: imageSizeArray[foundInPrefetched.imageSizeIndex || 0],
                            })
                        ).then((r) => {
                            declareAsPrefetched(i)
                        })
                    fetching_triggered[sequence_id + '-' + imageSizeIndex + '-' + i] = true
                }
            }
        } else {
            // console.log('NOPE!!!', { imageSizeIndex })
        }
    }, [imageSizeIndex, sequence_id])
    // console.log({ prefetchedImageArray }, { imageSizeIndex })

    useEffect(() => {
        imageSizeIndex !== 0 && setImageSizeIndex(0)
    }, [sequence_id])

    const click_zone_array = useMemo(() => config.click_zone_array.filter((cz) => cz.sequence_id === sequence_id), [sequence_id, start, end])

    const svg_url_array = useMemo(() => {
        const url_array: any[] = []
        for (let i = start; i <= end; i++) {
            url_array.push(
                click_zone_array.map((cz) => {
                    return { click_zone: cz, url: `click_zone/${cz.id}/${String(i).padStart(4, '0')}.svg`, image_nb: i }
                })
            )
        }
        return url_array
    }, [click_zone_array])

    const svg_array = useMemo(() => {
        let url_array = []
        for (let i = start || 0; i <= end; i++) {
            url_array.push({ image_nb: i, url: `click_zone/_concatenated/${sequence_id}/${String(i).padStart(4, '0')}.svg` })
        }
        return url_array
    }, [sequence_id])

    const selectedZoneRef = useRef<any>(null)

    useEffect(() => {
        // console.log('useEffect 5', { selectedZone })
        if (selectedZone) {
            if (config.concatenated_click_zones) {
                // try {
                !selectedZoneRef.current &&
                    (selectedZoneRef.current = document.querySelector(`[data-image-nb="${currentImage}"] path#path-id-${selectedZone.id}`))
                if (selectedZoneRef.current) {
                    config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                }
                // } catch (error) {
                // console.error(error)
                // }
            } else {
                !selectedZoneRef.current &&
                    (selectedZoneRef.current = document.querySelector(`[data-click-zone-id="${selectedZone.id}"][data-image-nb="${currentImage}"] path`))
                if (selectedZoneRef.current) {
                    config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                }
            }
        }
        return () => {
            selectedZoneRef.current = null
        }
    }, [selectedZone, currentImage])
    useEffect(() => {
        if (selectedZone) {
            if (config.concatenated_click_zones) {
                RAF(() => {
                    try {
                        !selectedZoneRef.current &&
                            (selectedZoneRef.current = document.querySelector(`[data-image-nb="${currentImage}"] path#path-id-${selectedZone.id}`))
                        if (selectedZoneRef.current) {
                            config.details_pane_position === 'tooltip' && selectedZone && setTooltipPosition(selectedZoneRef.current?.getBoundingClientRect())
                        }
                    } catch (error) {
                        console.error(error)
                    }
                })
            }
        }
        return () => {
            selectedZoneRef.current = null
        }
    }, [tick])

    // const [loadedOverlayImageArray, setLoadedOverlayImageArray] = useState<any[]>([])
    // const [loadedClickZoneArray, setLoadedClickZoneArray] = useState<any[]>([])

    const isThisImageOrPreviousOrNext = (image_nb: Number) =>
        withOffset(currentImage) === image_nb || withOffset(nextImage()) === image_nb || withOffset(previousImage()) === image_nb
    const canLoadTheRestOfImages =
        loadedImageArray.find((li) => li.sequence_id === sequence_id && li.image_nb === currentImage) &&
        loadedImageArray.find((li) => li.sequence_id === sequence_id && li.image_nb === withOffset(nextImage())) &&
        loadedImageArray.find((li) => li.sequence_id === sequence_id && li.image_nb === withOffset(previousImage()))
    // const canLoadTheRestOfClickZones =
    //     !click_zone_array?.length ||
    //     (loadedClickZoneArray.includes(currentImage) && loadedClickZoneArray.includes(nextImage()) && loadedClickZoneArray.includes(previousImage()))
    const canLoadTheRest = canLoadTheRestOfImages
    const allImagesForImageSizeLoaded = (index: Number) => {
        // console.log(
        //     'index:',
        //     index,
        //     loadedImageArray.filter((li) => li.sequence_id === sequence_id && li.imageSizeIndex === imageSizeIndex)?.length,
        //     end - start + 1
        // )
        return loadedImageArray.filter((li) => li.sequence_id === sequence_id && li.imageSizeIndex === imageSizeIndex)?.length >= end - start + 1
    }
    useEffect(() => {
        // console.log(imageSizeIndex, allImagesForImageSizeLoaded(imageSizeIndex))
        allImagesForImageSizeLoaded(imageSizeIndex) && imageSizeIndex < imageSizeArray.length - 1 && setImageSizeIndex(imageSizeIndex + 1)
    }, [loadedImageArray, imageSizeIndex])

    // const resizeObject = config.progressive_loading ? imageSizeArray[imageSizeIndex] : imageSizeArray[imageSizeArray.length - 1]
    // const canLoadTheRest = canLoadTheRestOfImages && canLoadTheRestOfClickZones

    // console.log({ canLoadTheRestOfImages }, { canLoadTheRestOfClickZones }, { canLoadTheRest })

    /**
     * Loading policy
     *
     * 1. Load 1 large image (current), load the rest of images small incrementally, close to the current one
     * 2. Load current zones, after all the images have loaded, load the zones
     *
     * left_threshold = currentImage
     * right_threshold = currentImage
     * 1st batch is right_threshold + Math.ceil(image_thread_max /2)
     * 1st batch is left_threshold - Math.floor(image_thread_max /2)
     *
     * pool = right_threshold + Math.ceil(image_thread_max /2), when finished increase right threshold by Math.ceil(image_thread_max /2)
     * pool = left_threshold - Math.floor(image_thread_max /2), when finished decrease left threshold by Math.floor(image_thread_max /2)
     *
     *
     * when first image is loaded:
     * loadedImageArray.includes(currentImage)
     */

    const sequence_background_color = config.color_array?.find((c) => c.id === 'sequence_background')?.color
    // console.count('Sequence rendered')

    const { selected_click_zone_type_id_array } = useAppContext()

    const id_prefix = click_zone_array.map((cz) => `.${cz.click_zone_type_id} path#path-id-${cz.id}`).join(', ')
    const selected_zone_click_type_css =
        (selected_click_zone_type_id_array &&
            selected_click_zone_type_id_array?.map(
                (czt) => `${id_prefix} {
    display: none;
}`
            ).join(`

`)) ??
        ''

    const click_zone_style = click_zone_array.map((cz) => {
        const click_zone_type = config.click_zone_type_array?.find((czt) => czt.id === cz.click_zone_type_id)!
        const zone_color = cz.color || click_zone_type?.color || zone_color_emphasis
        // console.log(`zone ${cz.id}, color: ${zone_color}`)

        // .click-zone path{
        //     ${zone_stroke_color ? `stroke: ${zone_stroke_color} !important;` : ''}
        //     ${zone_stroke_color ? `stroke-width: ${zone_stroke_width}px !important;` : ''}
        // }
        return `path#path-id-${cz.id} {
    ${zone_color ? `fill: ${zone_color} !important; opacity: 0.5 !important` : ''}
}
path#path-id-${cz.id}:hover {
    ${zone_color ? `fill: ${zone_color} !important; opacity: 0.7 !important` : ''}
}`
    }).join(`
`)

    const [isInitiated, setIsInitiated] = useState(false)
    const [isSequenceInitiated, setSequenceIsInitiated] = useState(false)
    useEffect(() => {
        return () => setSequenceIsInitiated(false)
    }, [sequence_id])
    // useEffect(() => {
    //     if (
    //         !isInitiated &&
    //         loadedImageArray.find((pi) => pi.image_nb === withOffset(currentImage) && pi.sequence_id === sequence.id && pi.imageSizeIndex === imageSizeIndex)
    //     ) {
    //         // RAF(() => RAF(() => setIsInitiated(true)))
    //         // setTimeout(() => setIsInitiated(true), 500)
    //     }
    // }, [loadedImageArray])

    if (!isInitiated || !isSequenceInitiated) {
        const currentImageRef: any = document.querySelector('.current-image')
        if (!isInitiated && currentImageRef?.complete) {
            setIsInitiated(true)
        }
        if (!isSequenceInitiated && currentImageRef?.complete) {
            setSequenceIsInitiated(true)
        }
    }
    // useEffect(() => {
    //     return () => setIsInitiated(false)
    // }, [sequence_id])

    const initiatedClassName = isInitiated ? ' sequence-initiated ' : ' sequence-not-initiated '

    useEffect(() => {
        let original: any
        if (sequence_background_color) {
            original = document.body.style.backgroundColor
            document.body.style.backgroundColor = sequence_background_color
        }
        return () => {
            sequence_background_color && (document.body.style.backgroundColor = original)
        }
    }, [])

    const { left, top, width, height } = useDisplayContext()
    // console.log({ left, top, width, height })

    const clickZoneTypeClassName =
        (config.click_zone_type_array &&
            selected_click_zone_type_id_array?.length &&
            config.click_zone_type_array
                .map((czt) => (selected_click_zone_type_id_array.includes(czt.id) ? undefined : czt.id))
                .filter(Boolean)
                .join(' ')) ||
        ''

    return (
        <>
            <div className="spinner-dual-ring" />
            <div
                className={'flex items-center justify-center max-w-full max-h-full w-full h-full' + initiatedClassName}
                style={sequence_background_color ? { backgroundColor: sequence_background_color } : undefined}
            >
                {((zone_color || zone_stroke_color || zone_stroke_width) && (
                    <>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: `
${click_zone_style}
${selected_zone_click_type_css}

`,
                            }}
                        />
                    </>
                )) ||
                    null}
                {/* <ul className="absolute top-0 left-0" style={{ zIndex: 10 }}>
                    {click_zone_array.map((cz) => (
                        <li
                            key={cz.id}
                            onClick={() => {
                                setIsOpen(true)
                                setSelectedZone(cz)
                            }}
                        >
                            {cz.label}
                        </li>
                    ))}
                </ul> */}
                <div
                    id="mouse-target"
                    ref={mouseTargetRef}
                    className={'absolute top-0 left-0 max-w-full max-h-full w-full h-full ' + clickZoneTypeClassName}
                    style={width ? { left, top, width, height } : undefined}
                >
                    {(config.concatenated_click_zones &&
                        svg_array.map(
                            (svg) =>
                                ((canLoadTheRest || isThisImageOrPreviousOrNext(svg.image_nb)) && (
                                    <LazySVG
                                        key={svg.url}
                                        src={svg.url}
                                        onClick={(e: any) => {
                                            const click_zone_id = e.target.id.replace('path-id-', '')
                                            const click_zone = click_zone_array.find((cz) => cz.id === click_zone_id)
                                            if (click_zone?.target_sequence_id) {
                                                const sequence = config.sequence_array.find((s) => s.id === click_zone?.target_sequence_id)
                                                const sequence_group = config.sequence_group_array.find((sg) => sg.id === sequence?.sequence_group_id)
                                                sequence &&
                                                    history.push(`${config.base}${sequence_group?.url || sequence_group?.id}/${sequence.url || sequence.id}`)
                                            } else {
                                                setIsOpen(true)
                                                setSelectedZone(click_zone)
                                                config.details_pane_position && setTooltipPosition(e.currentTarget.getBoundingClientRect())
                                            }
                                        }}
                                        className={
                                            'cursor-pointer absolute pointer-events-none max-w-full max-h-full w-full h-full object-contain' +
                                            (svg.image_nb === currentImage ? ' click-zone' : ' no-click-zone')
                                        }
                                        visible={svg.image_nb === currentImage}
                                        image_nb={svg.image_nb}
                                        // onLoad={() => setLoadedClickZoneArray((arr: any[]) => [...arr, url.image_nb])}
                                        onLoad={() => setTick(tick + 1)}
                                        selected_zone_id={selectedZone?.id}
                                    />
                                )) ||
                                null
                        )) ||
                        svg_url_array.map((url_group: any[], group_index) =>
                            url_group.map((urlObj, index) => {
                                return (
                                    ((canLoadTheRest || isThisImageOrPreviousOrNext(urlObj.image_nb)) && (
                                        <LazySVG
                                            key={urlObj.url + '.' + group_index}
                                            src={urlObj.url}
                                            onClick={(e: any) => {
                                                if (urlObj.click_zone?.target_sequence_id) {
                                                    const sequence = config.sequence_array.find((s) => s.id === urlObj.click_zone?.target_sequence_id)
                                                    sequence && history.push(`${config.base}${sequence.sequence_group_id}/${sequence.id}`)
                                                } else {
                                                    setIsOpen(true)
                                                    setSelectedZone(urlObj.click_zone)
                                                    console.log(e.currentTarget)
                                                    config.details_pane_position && setTooltipPosition(e.currentTarget.getBoundingClientRect())
                                                }
                                            }}
                                            className={
                                                'cursor-pointer absolute pointer-events-none max-w-full max-h-full w-full h-full object-contain' +
                                                (group_index === currentImage ? ' click-zone' : ' no-click-zone')
                                            }
                                            visible={group_index === currentImage}
                                            click_zone={urlObj.click_zone}
                                            image_nb={urlObj.image_nb}
                                            // onLoad={() => setLoadedClickZoneArray((arr: any[]) => [...arr, urlObj.image_nb])}
                                        />
                                    )) ||
                                    null
                                )
                            })
                        )}
                </div>
                {image_url_array.map((urlObj: any, index: number) => {
                    const foundInPrefetched = prefetchedImageArray
                        .filter((pi) => pi.image_nb === index && pi.sequence_id === sequence.id)
                        .reduce((acc, ip) => {
                            return acc === null || ip.imageSizeIndex >= (acc?.imageSizeIndex ?? 0) ? ip : acc
                        }, null)
                    // console.log({ foundInPrefetched }, currentImage)
                    // const iS = foundInPrefetched.imageSizeIndex || 0
                    const iSA = imageSizeArray[foundInPrefetched?.imageSizeIndex ?? 0]
                    return (
                        (foundInPrefetched && (
                            // ((isThisImageOrPreviousOrNext(urlObj.image_nb) || foundInPrefetched) && (
                            <Image
                                key={urlObj.url}
                                src={urlObj.url}
                                alt={`Sequence ${sequence?.label}, Image ${index}`}
                                // resize={{ width: 768, height: 432 }}
                                resize={iSA}
                                className={
                                    'max-w-full max-h-full w-full h-full object-contain image-sequence select-none' +
                                    ((sequence.offset ?? 0) === index ? ' image-default ' : ' ') +
                                    (iSA?.className || '') +
                                    (index === withOffset(currentImage)
                                        ? ' current-image'
                                        : isThisImageOrPreviousOrNext(urlObj.image_nb)
                                        ? ' hidden'
                                        : ' hidden')
                                }
                                onLoad={() => {
                                    // console.log(`image ${urlObj.image_nb} loaded (imageSizeIndex: ${imageSizeIndex})`)
                                    setLoadedImageArray((arr) =>
                                        arr.find((pi) => pi.image_nb === index && pi.sequence_id === sequence.id && pi.imageSizeIndex === imageSizeIndex)
                                            ? arr
                                            : [...arr, { imageSizeIndex, image_nb: urlObj.image_nb, sequence_id: sequence.id }]
                                    )
                                    // console.log('HEY!!!', foundInPrefetched, imageSizeIndex)
                                    // setPrefetchedImageArray((arr) =>
                                    //     arr.find((pi) => pi.image_nb === index && pi.sequence_id === sequence.id && pi.imageSizeIndex === imageSizeIndex)
                                    //         ? arr
                                    //         : [...arr, { imageSizeIndex, sequence_id: sequence.id, image_nb: urlObj.image_nb }]
                                    // )
                                }}
                            />
                        )) ||
                        null
                    )
                })}
                <div id="sequence-overlay" className="absolute top-0 left-0 max-w-full max-h-full w-full h-full pointer-events-none">
                    {image_overlay_url_array.map((urlObj: any, index: number) => {
                        return (
                            ((canLoadTheRest || isThisImageOrPreviousOrNext(urlObj.image_nb)) && (
                                <Image
                                    key={urlObj.url}
                                    src={urlObj.url}
                                    alt={`Sequence Overlay ${sequence?.label}, Image ${index}`}
                                    // resize={resizeObject}
                                    className={
                                        'absolute top-0 left-0 max-w-full max-h-full w-full h-full object-contain select-none' +
                                        (index === withOffset(currentImage) ? '' : ' invisible')
                                    }
                                    // onLoad={() =>
                                    //     +console.log(`overlay image ${urlObj.image_nb} loaded`) || setLoadedOverlayImageArray((arr) => [...arr, urlObj.image_nb])
                                    // }
                                />
                            )) ||
                            null
                        )
                    })}
                </div>
                {(compass && <Compass currentImage={currentImage} />) || null}
                {(canLoadTheRest && upSequence && (
                    <Image
                        src={`sequence/${upSequence?.id}/${String(withOffsetUp(currentImage)).padStart(4, '0')}.jpg`}
                        resize={imageSizeArray[imageSizeArray.length - 1]}
                        className="hidden"
                    />
                )) ||
                    null}
                {(canLoadTheRest && downSequence && (
                    <Image
                        src={`sequence/${downSequence?.id}/${String(withOffsetDown(currentImage)).padStart(4, '0')}.jpg`}
                        resize={imageSizeArray[imageSizeArray.length - 1]}
                        className="hidden"
                    />
                )) ||
                    null}

                {(selectedZone && (
                    <DetailsPane
                        position={config.details_pane_position || 'left'}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        click_zone={selectedZone}
                        tooltipPosition={tooltipPosition}
                        tick={tick}
                    />
                )) ||
                    null}
            </div>
            {(admin_overlay && <SequenceAdminOverlay currentImage={currentImage} withOffsetCurrentImage={withOffset(currentImage)} />) || null}
        </>
    )
})

const SequenceAdminOverlay = (props: any) => {
    const sequenceApi = useApi()
    const offsetInput = useInput(0)

    const { sequence_id } = useGetParams()
    const { currentImage, withOffsetCurrentImage } = props

    return (
        <div className="fixed bottom-0 right-0 p-4 admin-sequence-overlay">
            <dl>
                <dd>Current image: </dd>
                <dt>{currentImage}</dt>
            </dl>
            <dl>
                <dd>Offset image: </dd>
                <dt>{withOffsetCurrentImage}</dt>
            </dl>
            <form onSubmit={(e: any) => +e.preventDefault() || false}>
                <label htmlFor="offset">Offset</label>
                <Input type="number" id="offset" {...offsetInput.bind} />
                <Button
                    onClick={() => {
                        sequenceApi.post('sequence/offset', { sequence_id, offset: offsetInput.value })
                    }}
                >
                    Go
                </Button>
            </form>
        </div>
    )
}

export default Sequence
