import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { TypeSequence, TypeSequenceOverlay } from '../types'
import useConfig from './useConfig'

const useGetParams = (payload: any = {}) => {
    let sequence_id: string
    ;({ sequence_id } = payload)
    const config = useConfig()

    let navigation_item_url: string = ''
    const { params } = useRouteMatch() as any

    let sequence_group_id: string | undefined, image: string, zone: any
    ;({ sequence_group_id = config.sequence_group_array?.length ? config.sequence_group_array[0]?.id : undefined, image, sequence_id, zone } = params)
    sequence_group_id = config.sequence_group_array.find((b) => b.id === sequence_group_id)
        ? sequence_group_id
        : config.sequence_group_array.find((sg) => sg.url === sequence_group_id)?.id
    sequence_id = config.sequence_array.find((s) => s.id === sequence_id)
        ? sequence_id
        : config.sequence_array.find((s) => s.url === sequence_id && s.sequence_group_id === sequence_group_id)?.id || sequence_id
    ;({ sequence_group_id: navigation_item_url } = params)
    // console.log(useRouteMatch())
    let selected_navigation_item = config.navigation_array?.find((ni) => ni.url !== void 0 && ni.url === navigation_item_url)
    if (!selected_navigation_item && !navigation_item_url) {
        selected_navigation_item = config.navigation_array?.find((ni) => ni.url === '')
    }
    if (!selected_navigation_item && sequence_id) {
        selected_navigation_item = config.navigation_array?.find((ni) => ni.target_model === 'sequence' && ni.target_id === sequence_id)
    }
    if (!selected_navigation_item && sequence_group_id) {
        selected_navigation_item = config.navigation_array?.find((ni) => ni.target_model === 'sequence_group' && ni.target_id === sequence_group_id)
    }

    const path = params.sequence_group_id
    const subpath = params.sequence_id
    const gallery =
        (subpath && config?.gallery_array?.find((g) => subpath && (g.url === subpath || g.id === subpath))) ||
        config?.gallery_array?.find((g) => path && (g.url === path || g.id === path))
    if (!selected_navigation_item && gallery && subpath) {
        selected_navigation_item = config.navigation_array?.find((ni) => ni.target_model === 'gallery' && ni.target_id_array?.includes(subpath))
    }
    if (!selected_navigation_item && gallery && path) {
        selected_navigation_item =
            config.navigation_array?.find((ni) => ni.url === path) ||
            config.navigation_array?.find((ni) => ni.target_model === 'gallery' && ni.target_id_array?.includes(gallery.id))
    }
    const gallery_id = gallery?.id
    // console.log(navigation_item_url, { selected_navigation_item }, selected_navigation_item?.label)

    return useMemo(() => {
        let sequence_group, sequence_array: TypeSequence[], sequence: TypeSequence, sequence_overlay: TypeSequenceOverlay | undefined
        if (sequence_id) {
            sequence = (sequence_id && config.sequence_array.find((l) => l.id === sequence_id)) as TypeSequence
            sequence_group_id = sequence?.sequence_group_id
            sequence_group = config.sequence_group_array.find((b) => b.id === sequence_group_id)
            sequence_array = sequence_group_id
                ? config.sequence_array.filter((l: TypeSequence) => l.sequence_group_id === sequence_group_id)
                : config.sequence_array
        } else {
            sequence_group = config.sequence_group_array.find((b) => b.id === sequence_group_id)
            sequence_array = sequence_group_id
                ? config.sequence_array.filter((l: TypeSequence) => l.sequence_group_id === sequence_group_id)
                : config.sequence_array

            sequence_id = sequence_id ?? sequence_array.find((l) => l.default)?.id ?? sequence_array[0]?.id
            sequence = (sequence_id && sequence_array.find((l) => l.id === sequence_id)) as TypeSequence
        }

        if (sequence_id) {
            sequence_overlay = config.sequence_overlay_array?.find((so) => so.sequence_id === sequence_id)
        }

        const start = sequence?.image_start ?? sequence_group?.image_start ?? config.image_start
        const end = sequence?.image_end ?? sequence_group?.image_end ?? config.image_end

        const reverse_rotation = sequence?.reverse_rotation ?? sequence_group?.reverse_rotation ?? config.reverse_rotation
        const compass = sequence?.compass ?? sequence_group?.compass ?? config.compass
        const compass_offset = sequence?.compass_offset ?? sequence_group?.compass_offset ?? config.compass_offset

        const zone_stroke_color = zone?.zone_stroke_color ?? sequence?.zone_stroke_color ?? sequence_group?.zone_stroke_color ?? config.zone_stroke_color
        const zone_stroke_width = zone?.zone_stroke_width ?? sequence?.zone_stroke_width ?? sequence_group?.zone_stroke_width ?? config.zone_stroke_width
        const zone_color = zone?.zone_color ?? sequence?.zone_color ?? sequence_group?.zone_color ?? config.zone_color
        const zone_color_emphasis =
            zone?.zone_color_emphasis ?? sequence?.zone_color_emphasis ?? sequence_group?.zone_color_emphasis ?? config.zone_color_emphasis

        return {
            sequence_group_id,
            image,
            sequence_id,
            zone,
            sequence_group,
            sequence,
            sequence_array,
            sequence_overlay,
            start,
            end,
            reverse_rotation,
            compass,
            compass_offset,
            zone_stroke_color,
            zone_stroke_width,
            zone_color,
            zone_color_emphasis,
            selected_navigation_item,
            gallery_id,
        }
    }, [config, sequence_group_id, image, sequence_id, zone, selected_navigation_item, gallery_id])
}

export default useGetParams
