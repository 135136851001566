import { useState, useMemo, useEffect } from 'react'
import { API_URL } from '../constants'

const useApi = (params?: any) => {
    const [response, setResponse] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const post = (url: string, params?: any) => {
        setIsLoading(true)
        return fetch(API_URL + url, {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: (params && JSON.stringify(params)) || undefined,
        })
            .then((r) => r.json())
            .then((r: any) => {
                // console.log('HERE success')
                setResponse(r)
                setIsLoading(false)
                setIsSuccess(true)
            })
    }

    useEffect(() => {
        params?.onSuccess?.()
    }, [isSuccess])

    // console.log('useApi status:', {
    //     response,
    //     isLoading,
    //     isSuccess,
    //     post,
    //     bind: { isLoading },
    // })

    return useMemo(() => {
        // console.log('useApi RETURNING!!!')
        return {
            response,
            isLoading,
            isSuccess,
            post,
            bind: { isLoading },
        }
    }, [response, isLoading, isSuccess])
}

export default useApi
