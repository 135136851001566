import React, { useMemo, useState, useEffect } from 'react'
import Page from './Page'
import useConfig from '../hooks/useConfig'
import Input, { Label } from './Input'
import Button from './Button'
import FileInput from './FileInput'
import Image, { Polaroid } from './Image'
import ImageStack from './ImageStack'
import Icon, { AddIcon, RemoveIcon } from './Icon'
import LazySVG from './LazySVG'
import { useInput, useApi, useSelect } from './hooks'
import {
    TypeConfigContext,
    TypeImageMenu,
    TypeImageMenuZone,
    TypeSequenceGroup,
    TypeSequence,
    TypeSequenceOverlay,
    TypeClickZone,
    TypeColor,
    TypeStaticPage,
    TypeConfig,
} from '../types'
import { FILE_URL, IMAGE_HOST, IMAGE_PATH, THUMBNAILS_SIZE } from '../constants'
import { Link } from 'react-router-dom'
import { updateObjInArray } from '../modules/helpers'
const shortid = require('shortid')

const getNewId = () => shortid.generate()

const thumbnail_placeholder_style = {
    width: THUMBNAILS_SIZE + 'px',
    height: THUMBNAILS_SIZE + 'px',
}

const ImageMenuInputGroup = React.memo(function ImageMenuInputGroup(props: any) {
    const { image_menu, setImageMenuArray, setImageMenuZoneArray, addImageMenuZone } = props
    const config = useConfig()
    const altInput = useInput({
        value: image_menu.alt,
        onChange: (v: any) => setItemInArrayFactory(setImageMenuArray)((a: any) => a.id === image_menu.id)((im: TypeImageMenu) => ({ ...im, alt: v })),
    })

    const image_menu_zone = config.image_menu_zone_array.filter((imz) => imz.image_menu_id === image_menu.id)

    return (
        <div className="flex mb-6">
            <div className="">
                <Polaroid
                    src={'image-menu/' + image_menu.id + '.' + (image_menu.extension || '') + '?' + image_menu.key}
                    resize={{ format: 'cover', width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}
                    className="border"
                />
                <FileInput
                    payload={{ image_menu_id: image_menu.id }}
                    onSuccess={(r: any) => {
                        config.refresh()
                    }}
                    className="inline-block mt-3"
                />
            </div>
            <div className="flex-grow ml-6">
                <Input label="Name" {...altInput.bind} className="mb-6" />
                <h4>Zones</h4>
                <hr className="mb-3" />
                {image_menu_zone.map((imz) => (
                    <ImageMenuZoneInput key={imz.id} image_menu_zone={imz} setImageMenuZoneArray={setImageMenuZoneArray} />
                ))}
                <Button type="button" size="xs" onClick={() => addImageMenuZone(image_menu.id)} className="mt-5">
                    Add a zone
                </Button>
            </div>
        </div>
    )
})

type TypeStaticPageInputGroupProps = {
    static_page: TypeStaticPage
    setStaticPageArray: Function
}
const StaticPageInputGroup = React.memo(function StaticPageInputGroup(props: TypeStaticPageInputGroupProps) {
    const { static_page, setStaticPageArray } = props
    const labelInput = useInput({
        value: static_page.label,
        onChange: (v: any) => setItemInArrayFactory(setStaticPageArray)((a: any) => a.id === static_page.id)((sp: TypeStaticPage) => ({ ...sp, label: v })),
    })
    const pathInput = useInput({
        value: static_page.path,
        onChange: (v: any) => setItemInArrayFactory(setStaticPageArray)((a: any) => a.id === static_page.id)((sp: TypeStaticPage) => ({ ...sp, path: v })),
    })
    return (
        <>
            <div className="flex mb-6">
                <div className="flex-grow">
                    <Input label="Label" {...labelInput.bind} className="mb-6" />
                    <Input
                        label={
                            <>
                                Path{' '}
                                <Link to={`/${pathInput.value}`}>
                                    <Icon name="external-link-alt" className="cursor-pointer" />
                                </Link>
                            </>
                        }
                        {...pathInput.bind}
                        className="mb-6"
                    />
                </div>
            </div>
            <hr className="my-7" />
        </>
    )
})

type TypeImageMenuZoneInputProps = { image_menu_zone: TypeImageMenuZone; setImageMenuZoneArray: Function }

const ImageMenuZoneInput = React.memo(function ImageMenuZoneInput(props: TypeImageMenuZoneInputProps) {
    const { image_menu_zone, setImageMenuZoneArray } = props
    const { saveConfig, setConfig, ...config } = useConfig()

    const labelInput = useInput({
        value: image_menu_zone.label,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, label: v })),
    })
    const colorInput = useInput({
        value: image_menu_zone.color,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, color: v })),
    })
    const topInput = useInput({
        value: image_menu_zone.top,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, top: v })),
    })
    const leftInput = useInput({
        value: image_menu_zone.left,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, left: v })),
    })
    const widthInput = useInput({
        value: image_menu_zone.width,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, width: v })),
    })
    const heightInput = useInput({
        value: image_menu_zone.height,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, height: v })),
    })

    const attachFile = () => {
        const click_zone_id = getNewId()
        const newConfig = {
            ...config,
            click_zone_array: [...config.click_zone_array, { id: click_zone_id, image_menu_zone_id: image_menu_zone.id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const target_option_array = useMemo(
        () => [
            ...config.sequence_group_array.map((sg) => ({ value: 'sequence_group-' + sg.id, label: `Sequence Group: ${sg.label}` })),
            ...config.static_page_array.map((sp) => ({ value: 'static_page-' + sp.id, label: `Static Page: ${sp.label}` })),
        ],
        [config.sequence_group_array, config.static_page_array]
    )
    const targetInput = useSelect({
        value: target_option_array.find(
            (o: any) =>
                o.value === 'sequence_group-' + image_menu_zone.target?.sequence_group_id || o.value === 'static_page-' + image_menu_zone.target?.static_page_id
        ),
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({
                ...imz,
                target: { [v.split('-')[0]]: v.split('-')[1] },
            })),
    })

    const click_zone = config.click_zone_array.find((cz) => cz.image_menu_zone_id === image_menu_zone.id)
    const [imageZoneSource, setimageZoneSource] = useState(click_zone ? 'file' : 'rectangle')
    return (
        <div className="mt-7">
            <Input label="Label" {...labelInput.bind} className="mb-6" />
            <Input label="Color" {...colorInput.bind} type="color" className="mt-6" />
            <div className="mt-3">
                <input
                    type="radio"
                    checked={imageZoneSource === 'rectangle'}
                    onChange={() => setimageZoneSource('rectangle')}
                    className="mr-2"
                    name={'rectangle_or_file_' + image_menu_zone.id}
                    id={'rectangle_' + image_menu_zone.id}
                />
                <label htmlFor={'rectangle_' + image_menu_zone.id}>Rectangle</label>
            </div>
            <div className="mb-3">
                <input
                    type="radio"
                    checked={imageZoneSource === 'file'}
                    onChange={() => setimageZoneSource('file')}
                    className="mr-2"
                    name={'rectangle_or_file_' + image_menu_zone.id}
                    id={'file_' + image_menu_zone.id}
                />
                <label htmlFor={'file_' + image_menu_zone.id}>File</label>
            </div>
            {imageZoneSource === 'rectangle' && (
                <div className="flex">
                    <Input {...topInput.bind} label="Top" className="mr-3" />
                    <Input {...leftInput.bind} label="Left" className="mr-3" />
                    <Input {...widthInput.bind} label="Width" className="mr-3" />
                    <Input {...heightInput.bind} label="Height" />
                </div>
            )}
            {imageZoneSource === 'file' && (
                <>
                    {(click_zone && (
                        <div>
                            <img src={`${FILE_URL}image/click_zone/${click_zone.id}/0000.svg`} alt="" width="100" height="100" />
                            Click zone attached{click_zone.file_count && <>: {click_zone.file_count} file uploaded</>}
                            <FileInput payload={{ image_menu_zone_id: image_menu_zone.id, click_zone_id: click_zone?.id }} onSuccess={() => {}} />
                        </div>
                    )) || (
                        <Button type="button" size="xs" onClick={attachFile} className="mt-5">
                            Attach a file
                        </Button>
                    )}
                </>
            )}
            <Input type="select" label="Target" options={target_option_array} {...targetInput.bind} className="mt-3" />
            <hr className="mt-7 mb-7" />
        </div>
    )
})

type TypeSequenceGroupInputGroupProps = {
    sequence_group: TypeSequenceGroup
    addSequence: Function
    deleteSequence: Function
    deleteSequenceGroup: Function
    setSequenceGroupArray: Function
    setSequenceArray: Function
    setClickZoneArray: Function
    addClickZone: Function
    deleteClickZone: Function
    addSequenceOverlay: Function
    addImage: Function
    addFile: Function
}
const SequenceGroupInputGroup = React.memo(function SequenceGroupInputGroup(props: TypeSequenceGroupInputGroupProps) {
    const {
        sequence_group,
        addSequence,
        deleteSequence,
        deleteSequenceGroup,
        setSequenceGroupArray,
        setSequenceArray,
        setClickZoneArray,
        addClickZone,
        deleteClickZone,
        addSequenceOverlay,
        addImage,
        addFile,
    } = props
    const config = useConfig()
    const labelInput = useInput({
        value: props.sequence_group.label,
        onChange: (v: any) =>
            setItemInArrayFactory(setSequenceGroupArray)((a: any) => a.id === sequence_group.id)((sg: TypeSequenceGroup) => ({ ...sg, label: v })),
    })
    const urlInput = useInput({
        value: props.sequence_group.url,
        onChange: (v: any) =>
            setItemInArrayFactory(setSequenceGroupArray)((a: any) => a.id === sequence_group.id)((sg: TypeSequenceGroup) => ({ ...sg, url: v })),
    })
    return (
        <>
            <div className="flex mb-6">
                <div className="flex-grow">
                    <Input label="Group Name" {...labelInput.bind} />
                    <Input label="URL" {...urlInput.bind} className="mt-3" />
                </div>
                <RemoveIcon onClick={() => deleteSequenceGroup(sequence_group.id)} />
            </div>
            <ul className="">
                {config.sequence_array
                    .filter((s: TypeSequence) => s.sequence_group_id === sequence_group.id)
                    .map((s: TypeSequence) => (
                        <li key={s.id} className="mt-4">
                            <SequenceInputGroup
                                sequence={s}
                                sequence_overlay={config.sequence_overlay_array?.find((so) => so.sequence_id === s.id)}
                                sequence_group={sequence_group}
                                setSequence={setItemInArrayFactory(setSequenceArray)((a: any) => a.id === s.id)}
                                deleteSequence={deleteSequence}
                                setClickZoneArray={setClickZoneArray}
                                addClickZone={addClickZone}
                                deleteClickZone={deleteClickZone}
                                addSequenceOverlay={addSequenceOverlay}
                                addImage={addImage}
                                addFile={addFile}
                            />
                        </li>
                    ))}
                <li>
                    <Button type="button" onClick={addSequence} className="mt-5">
                        Add Sequence
                    </Button>
                </li>
            </ul>
        </>
    )
})
type TypeSequenceInputGroupProps = {
    sequence: TypeSequence
    sequence_overlay?: TypeSequenceOverlay
    sequence_group: TypeSequenceGroup
    setSequence: Function
    deleteSequence: Function
    setClickZoneArray: Function
    addClickZone: Function
    deleteClickZone: Function
    addSequenceOverlay: Function
    addImage: Function
    addFile: Function
}
const SequenceInputGroup = React.memo(function SequenceInputGroup(props: TypeSequenceInputGroupProps) {
    const {
        sequence,
        sequence_overlay,
        setSequence,
        deleteSequence,
        setClickZoneArray,
        addClickZone,
        deleteClickZone,
        addSequenceOverlay,
        addImage,
        addFile,
        sequence_group,
    } = props
    const config = useConfig()
    const labelInput = useInput({ value: sequence.label, onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, label: v })) })
    const urlInput = useInput({ value: sequence.url, onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, url: v })) })
    const offsetInput = useInput({
        type: 'number',
        value: sequence.offset,
        onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, offset: v })),
    })
    const zoneOffsetInput = useInput({
        type: 'number',
        value: sequence.zone_offset,
        onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, zone_offset: v })),
    })
    const compassInput = useInput({
        type: 'checkbox',
        value: sequence.compass,
        onChange: (v: any) => {
            setSequence((s: TypeSequence) => ({ ...s, compass: !sequence.compass }))
        },
    })
    const compassOffsetInput = useInput({
        type: 'number',
        value: sequence.compass_offset,
        onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, compass_offset: v })),
    })
    return (
        <>
            <div className="flex mt-6">
                <div>
                    {(sequence.image_end && <ImageStack sequence={sequence} />) || null}
                    <div className="text-sm">
                        {(sequence.image_end !== void 0 && (
                            <div className="flex justify-between items-end mt-3">
                                {sequence.image_end + 1} image{sequence.image_end ? 's' : ''}
                                <FileInput
                                    multiple
                                    payload={{ sequence_id: sequence.id }}
                                    onSuccess={() => +console.log('Files uploaded for sequence', sequence.label, `(${sequence.id})`) || config.refresh()}
                                    className="inline-block mt-3"
                                />
                            </div>
                        )) || (
                            <div style={thumbnail_placeholder_style} className={'flex flex-col items-center justify-center border'}>
                                No images
                                <FileInput
                                    multiple
                                    payload={{ sequence_id: sequence.id }}
                                    onSuccess={() => +console.log('Files uploaded for sequence', sequence.label, `(${sequence.id})`) || config.refresh()}
                                    className="inline-block mt-3"
                                />
                            </div>
                        )}
                    </div>
                    {(sequence_overlay && (
                        <div className="text-sm flex flex-col mt-6">
                            <div>Overlay: {(sequence_overlay.image_end && <>{sequence_overlay.image_end} images</>) || null}</div>

                            <FileInput
                                multiple
                                payload={{ sequence_overlay_id: sequence_overlay.id }}
                                onSuccess={() =>
                                    +console.log(`Files uploaded for sequence_overlay - sequence ${sequence.label} (${sequence_overlay.id})`) ||
                                    config.refresh()
                                }
                                className="inline-block mt-3"
                            />
                        </div>
                    )) ||
                        null}
                </div>
                <div className="flex-grow ml-6">
                    <Input
                        label={
                            <>
                                Sequence Name{' '}
                                <Link to={`/${sequence_group.id}/${sequence.id}`}>
                                    <Icon name="external-link-alt" className="cursor-pointer" />
                                </Link>
                            </>
                        }
                        {...labelInput.bind}
                    />
                    <Input
                        label={
                            <>
                                URL{' '}
                                <Link to={`/${sequence_group.url}/${urlInput.value}`}>
                                    <Icon name="external-link-alt" className="cursor-pointer" />
                                </Link>
                            </>
                        }
                        {...urlInput.bind}
                        className="mt-3"
                    />
                    <Input label="Compass" {...compassInput.bind} className="mt-3" />
                    <div className="flex">
                        <Input label="Offset" {...offsetInput.bind} className="mt-3 mr-3" />
                        <Input label="Zone Offset" {...zoneOffsetInput.bind} className="mt-3 mr-3" />
                        <Input label="Compass Offset" {...compassOffsetInput.bind} className="mt-3 mr-3" />
                    </div>
                    <ul>
                        {config.click_zone_array
                            .filter((cz) => cz.sequence_id === sequence.id)
                            .map((cz) => (
                                <li key={cz.id}>
                                    <ClickZoneInputGroup
                                        click_zone={cz}
                                        sequence_id={sequence.id}
                                        setClickZone={setItemInArrayFactory(setClickZoneArray)((a: any) => a.id === cz.id)}
                                        deleteClickZone={deleteClickZone}
                                        addImage={addImage}
                                        addFile={addFile}
                                    />
                                </li>
                            ))}
                        <li>
                            <Button type="button" size="xs" onClick={() => addClickZone(sequence.id)} className="mt-5">
                                Add a zone
                            </Button>
                            <Button type="button" size="xs" onClick={() => addSequenceOverlay(sequence.id)} className="mt-5">
                                Add an overlay
                            </Button>
                        </li>
                    </ul>
                </div>
                <RemoveIcon onClick={() => deleteSequence(sequence.id)} />
            </div>
        </>
    )
})
type TypeClickZoneInputGroupProps = {
    click_zone: TypeClickZone
    sequence_id: string
    setClickZone: Function
    deleteClickZone: Function
    addImage: Function
    addFile: Function
}
const ClickZoneInputGroup = React.memo(function ClickZoneInputGroup(props: TypeClickZoneInputGroupProps) {
    const { click_zone, sequence_id, setClickZone, addImage, addFile } = props
    const { saveConfig, setConfig, ...config } = useConfig()
    const labelInput = useInput({ value: click_zone.label, onChange: (v: string) => setClickZone((s: TypeClickZone) => ({ ...s, label: v })) })
    const colorInput = useInput({ value: click_zone.color, onChange: (v: string) => setClickZone((s: TypeClickZone) => ({ ...s, color: v })) })
    const attachImage = () => {
        const image_id = getNewId()
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.map((cz) => (cz.id === click_zone.id ? { ...cz, image_id } : cz)),
            image_array: [...(config.image_array || []), { id: image_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const attachFile = () => {
        const file_id = getNewId()
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.map((cz) => (cz.id === click_zone.id ? { ...cz, file_id } : cz)),
            file_array: [...(config.file_array || []), { id: file_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    return (
        <div className="mt-6 flex">
            <div>
                <div style={thumbnail_placeholder_style} className="relative flex">
                    <Image src={`sequence/${sequence_id}/${String(0).padStart(4, '0')}.jpg`} resize={{ width: 135, height: 135 }} className="opacity-25" />
                    <LazySVG src={`click_zone/${click_zone.id}/${String(0).padStart(4, '0')}.svg`} className="absolute top-0 left-0 click-zone-thumbnail" />
                </div>
                <FileInput
                    multiple
                    payload={{ click_zone_id: click_zone.id }}
                    onSuccess={() => +console.log(`Files uploaded for click_zone ${click_zone.label}`) || config.refresh()}
                    className="inline-block mt-3"
                />
                {click_zone.file_count && (
                    <>
                        <br /> {click_zone.file_count} Files
                    </>
                )}
            </div>
            <div className="ml-6 flex-grow">
                <Input label="Zone Name" {...labelInput.bind} />
                <Input type="color" label="Color" {...colorInput.bind} className="mt-6" />
                {(click_zone.image_id && <>image</>) || (
                    <Button type="button" size="xs" onClick={attachImage} className="mt-5">
                        Attach an image
                    </Button>
                )}
                {(click_zone.file_id && <File file_id={click_zone.file_id} payload={{ click_zone_id: click_zone.id }} />) || (
                    <Button type="button" size="xs" onClick={attachFile} className="mt-5">
                        Attach a file
                    </Button>
                )}
            </div>
        </div>
    )
})

const File = React.memo(function File(props: { file_id: string; payload: any }) {
    const { file_id, payload } = props
    const config = useConfig()
    const file = config.file_array?.find((f) => f.id === file_id)
    return (
        <div>
            File: {file?.label}{' '}
            <FileInput
                payload={{ ...payload, file_id }}
                onSuccess={(r: any) => {
                    console.log(`File uploaded for`, payload)
                    config.refresh()
                }}
                className="inline-block mt-3"
            />
        </div>
    )
})

type TypeSettingsProps = any
// setPartialStateFactory(config, 'sequence_group_array')
const setPartialStateFactory = (setState: Function) => (key: string) => (Fn: Function) => setState((s: any) => ({ ...s, [key]: Fn(s[key]) }))
const setItemInArrayFactory = (setState: Function) => (IdentityFn: Function) => (Fn: Function) =>
    setState((s: any[]): any[] =>
        s.reduce((acc: any[], i: any) => {
            acc.push(IdentityFn(i) ? Fn(i) : i)
            return acc
        }, [])
    )

const Settings = React.memo(function Settings(props: TypeSettingsProps) {
    const config = useConfig()
    const { setConfig, refresh, saveConfig }: TypeConfigContext = config
    const baseInput = useInput(config.base)
    const titleInput = useInput(config.title)
    const configApi = useApi()

    useEffect(() => {
        setConfig((c: TypeConfigContext) => ({ ...c, base: baseInput.value }))
    }, [baseInput.value])

    useEffect(() => {
        setConfig((c: TypeConfigContext) => ({ ...c, title: titleInput.value }))
    }, [titleInput.value])

    const addImageMenu = () => {
        const newConfig = { ...config, image_menu_array: [...config.image_menu_array, { id: getNewId() }] }
        saveConfig(newConfig).then(() => {
            setConfig(newConfig)
        })
    }
    const addStaticPage = () => {
        const newConfig = { ...config, static_page_array: [...config.static_page_array, { id: getNewId() }] }
        saveConfig(newConfig).then(() => {
            setConfig(newConfig)
        })
    }
    const addImageMenuZone = (image_menu_id: string) => {
        const newConfig = {
            ...config,
            image_menu_zone_array: [...config.image_menu_zone_array, { id: getNewId(), image_menu_id }],
        }
        console.log('Adding click zone')
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const deleteImageMenu = (id: string) => {
        setConfig((s: TypeConfigContext) => ({ ...s, image_menu_array: s.image_menu_array.filter((im) => im.id !== id) }))
    }
    const addSequenceGroup = () => {
        const newConfig = { ...config, sequence_group_array: [...config.sequence_group_array, { id: getNewId() }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const addClickZoneType = () => {
        const newConfig = { ...config, click_zone_type_array: [...(config.click_zone_type_array ?? []), { id: getNewId() }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const deleteSequenceGroup = (sequence_group_id: string) => {
        const newConfig = {
            ...config,
            sequence_group_array: config.sequence_group_array.filter((sg) => sg.id !== sequence_group_id),
            sequence_array: config.sequence_array.filter((s) => s.sequence_group_id !== sequence_group_id),
            click_zone_array: config.click_zone_array.filter(
                (cz) => config.sequence_array.find((seq) => seq.id === cz.sequence_id)?.sequence_group_id !== sequence_group_id
            ),
        }
        /* sequenceGroupApi.post('delete', { sequence_group_id }).then(() => {
            setConfig(newConfig)
        }) */
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const addSequence = (sequence_group_id: string) => {
        const newConfig = {
            ...config,
            sequence_array: [...config.sequence_array, { id: getNewId(), sequence_group_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const deleteSequence = (sequence_id: string) => {
        const newConfig = {
            ...config,
            sequence_array: config.sequence_array.filter((seq) => seq.id !== sequence_id),
            click_zone_array: config.click_zone_array.filter((cz) => cz.sequence_id !== sequence_id),
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const deleteClickZoneType = (click_zone_type_id: string) => {
        const newConfig = {
            ...config,
            click_zone_type_array: config.click_zone_type_array?.filter((seq) => seq.id !== click_zone_type_id) ?? [],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const addClickZone = (sequence_id: string) => {
        const newConfig = {
            ...config,
            click_zone_array: [...config.click_zone_array, { id: getNewId(), sequence_id }],
        }
        console.log('Adding click zone')
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const addSequenceOverlay = (sequence_id: string) => {
        const newConfig = {
            ...config,
            sequence_overlay_array: [...config.sequence_overlay_array, { id: getNewId(), sequence_id }],
        }
        console.log('Adding sequence overlay')
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const deleteClickZone = (click_zone_id: string) => {
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.filter((cz) => cz.id !== click_zone_id),
        }
    }

    const save = (e?: any) => {
        e?.preventDefault?.()
        const { setConfig, refresh, saveConfig, ...c } = config
        saveConfig(c)
    }

    const root_option_array = useMemo(
        () => [
            ...config.image_menu_array.map((im) => ({ value: 'image_menu-' + im.id, label: im.alt })),
            ...config.static_page_array.map((sp) => ({ value: 'static_page-' + sp.id, label: sp.label })),
        ],
        [config.image_menu_array, config.static_page_array]
    )
    const rootInput = useSelect({
        value: root_option_array.find((o: any) => o.value === config.root?.target_id || o.value === config.root?.image_menu_id),
        onChange: (v: any) => {
            setConfig(
                (c: TypeConfigContext) =>
                    +console.log({ ...c, root: { ...c.root, target_id: v } }) || { ...c, root: { ...c.root, target_id: v?.value, label: v?.label } }
            )
        },
    })
    // console.log('hey!', rootInput?.value)

    const addColor = () => {
        const newConfig = {
            ...config,
            color_array: [...config.color_array, { id: getNewId() }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const addImage = ({ image_id }: { image_id: string }) => {
        const newConfig = { ...config, image_array: [...(config.image_array || []), { id: image_id }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const addFile = ({ file_id }: { file_id: string }) => {
        const newConfig = { ...config, file_array: [...(config.file_array || []), { id: file_id }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const [tab, setTab] = useState(window.location.hash ? window.location.hash.substring(1) : 'general')
    const progressiveLoadingInput = useInput({
        type: 'checkbox',
        value: config.progressive_loading,
        onChange: (e: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, progressive_loading: !config.progressive_loading }))
        },
    })
    const concatenatedClickZonesInput = useInput({
        type: 'checkbox',
        value: config.concatenated_click_zones,
        onChange: (e: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, concatenated_click_zones: !config.concatenated_click_zones }))
        },
    })
    const displayLogoInput = useInput({
        type: 'checkbox',
        value: config.display_logo,
        onChange: (e: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, display_logo: !config.display_logo }))
        },
    })
    const reverseRotationInput = useInput({
        type: 'checkbox',
        value: config.reverse_rotation,
        onChange: (e: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, reverse_rotation: !config.reverse_rotation }))
        },
    })
    const imageStartInput = useInput({
        type: 'number',
        value: config.image_start || 0,
        onChange: (v: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, image_start: v.image_start }))
        },
    })
    const imageEndInput = useInput({
        type: 'number',
        value: config.image_end,
        onChange: (v: any) => {
            setConfig((c: TypeConfigContext) => ({ ...c, image_end: v.image_end }))
        },
    })
    console.log('configApi', configApi)

    return (
        <Page>
            <form className="w-8/12 mx-auto" onSubmit={save}>
                <h1 className="mb-5">Project Settings</h1>

                <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="tablist">
                    <li className="nav-item" role="presentation" onClick={() => setTab('general')}>
                        <a
                            href="#general"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'general' ? 'active' : '')
                            }
                            role="tab"
                        >
                            General
                        </a>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('colors')}>
                        <a
                            href="#colors"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'colors' ? 'active' : '')
                            }
                            role="tab"
                        >
                            Colors
                        </a>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('image_menus')}>
                        <a
                            href="#image_menus"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'image_menus' ? 'active' : '')
                            }
                            role="tab"
                        >
                            Image-menus
                        </a>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('static_pages')}>
                        <a
                            href="#static_pages"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'static_pages' ? 'active' : '')
                            }
                            role="tab"
                        >
                            Static Pages
                        </a>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('sequence_groups')}>
                        <a
                            href="#sequence_groups"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'sequence_groups' ? 'active' : '')
                            }
                            role="tab"
                        >
                            Sequence Groups
                        </a>
                    </li>
                    <li className="nav-item" role="presentation" onClick={() => setTab('click_zone_types')}>
                        <a
                            href="#click_zone_types"
                            className={
                                'nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent ' +
                                (tab === 'click_zone_types' ? 'active' : '')
                            }
                            role="tab"
                        >
                            Zone Types
                        </a>
                    </li>
                </ul>
                {tab === 'general' && (
                    <>
                        {/* <h2 className="mb-6">General</h2> */}
                        <Input type="text" label="Base" {...baseInput.bind} />
                        <div className="mt-10">
                            <Input type="text" label="Project Title" {...titleInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Homepage" type="select" options={root_option_array} {...rootInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Display Logo" {...displayLogoInput.bind} />
                            <Image
                                src={'logo.png'}
                                alt="Logo"
                                onError={function (e: any) {
                                    e.target.style = 'display:none'
                                }}
                            />
                            {displayLogoInput.value && <FileInput payload={{ logo: true }} onSuccess={() => {}} />}
                        </div>

                        <div className="mt-10">
                            <Label>Favicon</Label>
                            <Image
                                src={'favicon.png'}
                                alt="Favicon"
                                onError={function (e: any) {
                                    e.target.style = 'display:none'
                                }}
                            />
                            <FileInput payload={{ favicon: true }} onSuccess={() => {}} />
                        </div>
                        {/* {config.display_logo === true && <div className="mt-10">test</div>} */}
                        <div className="mt-10">
                            <Input label="Image start" {...imageStartInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Image end" {...imageEndInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Reverse rotation" {...reverseRotationInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Progressive loading" {...progressiveLoadingInput.bind} />
                        </div>
                        <div className="mt-10">
                            <Input label="Concatenated click zones (recommended)" {...concatenatedClickZonesInput.bind} />
                        </div>
                    </>
                )}
                {tab === 'colors' && (
                    <>
                        {/* <h2 className="mb-6">Colors</h2> */}
                        <ul>
                            {config.color_array?.map((color) => (
                                <ThemeColorInput key={color.id} {...color} setConfig={setConfig} />
                            ))}
                        </ul>
                    </>
                )}
                {tab === 'image_menus' && (
                    <>
                        <div className="mt-10 mb-6 flex justify-between items-center">
                            {/* <h2>Image-menus</h2> */}
                            <Button type="button" onClick={addImageMenu} className="mt-5">
                                Add an image-menu
                            </Button>
                        </div>
                        <ul>
                            {config.image_menu_array.map((im: TypeImageMenu) => (
                                <li key={im.id}>
                                    <ImageMenuInputGroup
                                        image_menu={im}
                                        setImageMenuArray={setPartialStateFactory(setConfig)('image_menu_array')}
                                        setImageMenuZoneArray={setPartialStateFactory(setConfig)('image_menu_zone_array')}
                                        addImageMenuZone={addImageMenuZone}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {tab === 'static_pages' && (
                    <>
                        <div className="mt-10 mb-6 flex justify-between items-center">
                            {/* <h2>Static Pages</h2> */}
                            <Button type="button" onClick={addStaticPage} className="mt-5">
                                Add a static page
                            </Button>
                        </div>
                        <ul>
                            {config.static_page_array.map((sp: TypeStaticPage) => (
                                <li key={sp.id}>
                                    <StaticPageInputGroup static_page={sp} setStaticPageArray={setPartialStateFactory(setConfig)('static_page_array')} />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {tab === 'sequence_groups' && (
                    <>
                        <div className="mt-10 mb-6 flex justify-between items-center">
                            {/* <h2>Sequence groups</h2> */}
                            <Button type="button" onClick={addSequenceGroup} className="mt-5">
                                Add Sequence Group
                            </Button>
                        </div>
                        <ul>
                            {config.sequence_group_array.map((sg: TypeSequenceGroup) => (
                                <li key={sg.id}>
                                    <SequenceGroupInputGroup
                                        sequence_group={sg}
                                        addSequence={() => addSequence(sg.id)}
                                        deleteSequence={deleteSequence}
                                        deleteSequenceGroup={deleteSequenceGroup}
                                        setSequenceGroupArray={setPartialStateFactory(setConfig)('sequence_group_array')}
                                        setSequenceArray={setPartialStateFactory(setConfig)('sequence_array')}
                                        setClickZoneArray={setPartialStateFactory(setConfig)('click_zone_array')}
                                        addClickZone={addClickZone}
                                        addSequenceOverlay={addSequenceOverlay}
                                        deleteClickZone={addClickZone}
                                        addImage={addImage}
                                        addFile={addFile}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {tab === 'click_zone_types' && (
                    <>
                        <div className="mt-10 mb-6 flex justify-between items-center">
                            {/* <h2>Sequence groups</h2> */}
                            <Button type="button" onClick={addClickZoneType} className="mt-5">
                                Add a Zone Type
                            </Button>
                        </div>
                        {/* <h2 className="mb-6">Colors</h2> */}
                        <ul>
                            {config.click_zone_type_array?.map((czt) => (
                                <li key={czt.id}>
                                    <ClickZoneType click_zone_type={czt} setConfig={setConfig} deleteClickZoneType={deleteClickZoneType} />
                                    <hr className="mb-6" />
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                <div className="sticky bottom-0 flex justify-end px-5 py-5 mt-10 bg-white">
                    <Button onClick={save} color="primary" isLoading={configApi.isLoading}>
                        {configApi.isLoading
                            ? 'Saving'
                            : (configApi.isSuccess && (
                                  <>
                                      <Icon name="check" className="mr-2" />
                                      Saved
                                  </>
                              )) ||
                              'Save'}
                    </Button>
                </div>
            </form>
        </Page>
    )
})
type TypeClickZoneTypeProps = any
const ClickZoneType = React.memo(function ClickZoneType(props: TypeClickZoneTypeProps) {
    const { click_zone_type, setConfig, deleteClickZoneType } = props
    const { id, label, color } = click_zone_type
    return (
        <div className="flex">
            <div className="flex-grow">
                <Input
                    type="text"
                    value={label}
                    onChange={(e: any) => {
                        const value = e.currentTarget.value
                        setConfig((c: TypeConfig) => ({
                            ...c,
                            click_zone_type_array: updateObjInArray(c.click_zone_type_array)({ ...click_zone_type, label: value }),
                        }))
                    }}
                    label="Label"
                    className="mb-6"
                />
                <Input
                    type="color"
                    value={color}
                    onChange={(e: any) => {
                        const value = e.currentTarget.value

                        setConfig((c: TypeConfig) => ({
                            ...c,
                            click_zone_type_array: updateObjInArray(c.click_zone_type_array)({ ...click_zone_type, color: value }),
                        }))
                    }}
                    label="Color"
                    className="mb-6"
                />
            </div>
            <RemoveIcon onClick={() => deleteClickZoneType(id)} />
        </div>
    )
})

const ThemeColorInput = React.memo(function ThemeColorInput(props: any) {
    const { id, label, color, setConfig } = props

    return (
        <Input
            type="color"
            label={label}
            value={color}
            onChange={(e: any) => {
                const value = e.currentTarget.value
                setConfig((conf: TypeConfigContext) => ({
                    ...conf,
                    color_array: conf.color_array.map((c: TypeColor) => (c.id === id ? { ...c, color: value } : c)),
                }))
            }}
            className="mt-6"
        />
    )
})

export default Settings
