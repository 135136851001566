import React, { useEffect, useLayoutEffect, useState } from 'react'
import { IMAGE_HOST, IMAGE_PATH } from '../constants'
import { TypeClickZone } from '../types'
import { useConfig, useData, useDimensions, useId } from './hooks'

type TypeResize = {
    width: string | Number
    height: string | Number
    format?: 'cover' | 'contain'
}
type TypeObjectProps = {
    src: string
    alt?: string
    className?: string
    style?: any
    resize?: TypeResize
    onClick?: Function
    click_zone?: TypeClickZone
    image_nb?: string | number
    onLoad?: Function
    visible?: boolean
    selected_zone_id?: string
}

const style_hidden = {
    visibility: 'hidden',
} as React.CSSProperties

const LazySVG = React.memo(function LazySVG(props: TypeObjectProps) {
    const config = useConfig()
    const id = useId()
    let { src, click_zone, image_nb, className, visible, selected_zone_id } = props
    if (src.substring(0, IMAGE_PATH.length) === IMAGE_PATH) {
        src = src.substring(IMAGE_PATH.length)
    }
    const style = visible ? undefined : style_hidden
    const [innerHTML, setInnerHTML] = useState<any>(null)

    useEffect(() => {
        fetch(IMAGE_HOST + IMAGE_PATH + src)
            .then((r) => r.text())
            .then((payload) => {
                setInnerHTML(payload)
                props.onLoad?.(image_nb)
            })
    }, [src])

    // const [svgRef, dimensions] = useDimensions({ liveMeasure: true })
    // console.log({ dimensions })
    // useLayoutEffect(() => {
    // console.log({ dimensions })
    // }, [])
    useLayoutEffect(() => {
        const handleClick = (e: any) => {
            props.onClick?.(e)
        }
        if (config.concatenated_click_zones) {
            document.querySelectorAll(`#${id} path`)?.forEach((item) => {
                item.addEventListener('click', handleClick)
            })
        } else {
            document.querySelector(`#${id} path`)?.addEventListener('click', handleClick)
        }
        return () => {
            if (config.concatenated_click_zones) {
                document.querySelectorAll(`#${id} path`)?.forEach((item) => item.removeEventListener('click', handleClick))
            } else {
                document.querySelector(`#${id} path`)?.addEventListener('click', handleClick)
            }
        }
    }, [innerHTML])
    return (
        <div
            dangerouslySetInnerHTML={{ __html: innerHTML }}
            className={className}
            style={style}
            id={id}
            data-click-zone-id={click_zone?.id}
            data-image-nb={image_nb}
            data-selected-zone-id={visible && selected_zone_id}
        />
    )
})

export default LazySVG
